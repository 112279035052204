import Cookies from 'js-cookie';
import * as actionType from './actionsTypes';
import { resHandle } from '../helper';
import {
    login,
    signup,
    fastSignup,
    verifyemailotp,
    logout,
    forgotPassword,
    resetPassword,
    resendOtp,
    verifyFastOtp,
} from '../services/authService';

//import { addToCart } from '../services/programService';


let user = JSON.parse(localStorage.getItem('user'));
const initialState = user ? {loggedIn: true, userProfile: user } : {};


/************************************************************************************/
// User Reducer
/************************************************************************************/


const userReducer = (state = initialState, action) => {

    switch (action.type) {


        case actionType.LOGIN_REQIEST:
        case actionType.SIGNUP_REQIEST:
            return {
                ...state,
                loading: true,
            };


        case actionType.LOGIN_SUCCESS:
        case actionType.SIGNUP_SUCCESS:
            return {
                ...state,
                loggedIn: true,
                loading: false,
                userProfile: action.payload
            };

        case actionType.UPDATE_USER_PROFILE: 
        return {
                ...state,
                userProfile: action.payload
            };

        case actionType.CHECK_IS_COUNSELOR: 
        return {
                ...state,
                userProfile: action.payload
            };


        case actionType.LOGIN_FAILURE:
        case actionType.SIGNUP_FAILURE:
            return {
                loading: false,
                error: action.payload
            };


        case actionType.CLEAR_ERROR_MSG:
            return {
                ...state,
                error: ''
            };

        case actionType.CLEAR_LOADER:
            return {
                ...state,
                loading: false
            };



        case actionType.LOGOUT:
            return {};

            
        default:
            return state
    }
}




/************************************************************************************/
// All User Actions
/************************************************************************************/




export const clearErrorMsgAction = () => (dispatch => dispatch({ type: actionType.CLEAR_ERROR_MSG }));

export const checkIsCounselorAction = payload => (dispatch => dispatch({ type: actionType.CHECK_IS_COUNSELOR, payload }));





export const signupAction = params => async dispatch => {
    dispatch({ type: actionType.LOGIN_REQIEST });
    try {
        let user = await signup(params);
        let { status, data } = resHandle(user);
        if(status){

            localStorage.setItem('userId', data.userId);

            dispatch({ type: actionType.SIGNUP_SUCCESS, payload: data });
            dispatch({ type: actionType.AUTH_MODAL, payload: 0 });
            dispatch({ type: actionType.IS_OTP_MODAL });
        }else{
            dispatch({ type: actionType.SIGNUP_FAILURE, payload: data });
        }
    } catch (error) {
        dispatch({ type: actionType.SIGNUP_FAILURE, payload: error });
    }
}


export const fastSignupAction = params => async dispatch => {
    dispatch({ type: actionType.LOGIN_REQIEST });
    try {
        let user = await fastSignup(params);
        let { status, data } = resHandle(user);
        if(status){

            localStorage.setItem('userId', data.userId);

            dispatch({ type: actionType.SIGNUP_SUCCESS, payload: data });
            dispatch({ type: actionType.AUTH_MODAL, payload: 0 });
            dispatch({ type: actionType.IS_OTP_MODAL });
        }else{
            dispatch({ type: actionType.SIGNUP_FAILURE, payload: data });
        }
    } catch (error) {
        dispatch({ type: actionType.SIGNUP_FAILURE, payload: error });
    }
}










export const verifyemailotpAction = params => async dispatch => {
    dispatch({ type: actionType.LOGIN_REQIEST });
    try {
        let user = await verifyemailotp(params);
        let { status, data } = resHandle(user);
        if(status){
            localStorage.setItem('accessToken', data.accessToken);
            localStorage.setItem('user', JSON.stringify(data.userProfile));
            localStorage.removeItem('cartitems');
            localStorage.removeItem('NonLoginUserID');
            localStorage.removeItem('NonLoginaccessToken');

            dispatch({ type: actionType.LOGIN_SUCCESS, payload: data.userProfile });
            dispatch({ type: actionType.IS_OTP_MODAL });

            Cookies.set('accessToken2', data.accessToken);
            Cookies.set('user2', JSON.stringify(data.userProfile));
            window.location.reload();
        }else{
            dispatch({ type: actionType.SIGNUP_FAILURE, payload: data });
        }
    } catch (error) {
        dispatch({ type: actionType.SIGNUP_FAILURE, payload: error });
    }
}


export const verifyFastEmailotpAction = params => async dispatch => {
    dispatch({ type: actionType.LOGIN_REQIEST });
    try {
        let user = await verifyFastOtp(params);
        let { status, data } = resHandle(user);
        if(status){
            localStorage.setItem('accessToken', data.accessToken);
            localStorage.setItem('user', JSON.stringify(data.userProfile));
            localStorage.removeItem('cartitems');
            localStorage.removeItem('NonLoginUserID');
            localStorage.removeItem('NonLoginaccessToken');
            dispatch({ type: actionType.LOGIN_SUCCESS, payload: data.userProfile });
            dispatch({ type: actionType.AUTH_MODAL, payload: 0 });
            if(data?.userProfile?.UserType == 4){
                window.location.href = '/profile/information';
            }else{
                window.location.reload();
            }
            Cookies.set('accessToken2', data.accessToken);
            Cookies.set('user2', JSON.stringify(data.userProfile));
        }else{
            dispatch({ type: actionType.SIGNUP_FAILURE, payload: data });
        }
    } catch (error) {
        dispatch({ type: actionType.SIGNUP_FAILURE, payload: error });
    }
}


export const logoutAction = () => async dispatch => {
        localStorage.clear();
        dispatch({ type: actionType.LOGOUT });
        window.location.href = '/';
}


export const upadteUserProfileAction = payload => (dispatch => dispatch({ type: actionType.UPDATE_USER_PROFILE, payload}))











export const loginAction = params => async dispatch => {
    dispatch({ type: actionType.SIGNUP_REQIEST });
    try {
        let user = await login(params);
        let { status, data } = resHandle(user);
        if(status){
            if(data.statusCode == 12) {
                localStorage.setItem('userId', data.userId);
                dispatch({ type: actionType.IS_OTP_MODAL });
                dispatch({ type: actionType.CLEAR_LOADER });
            }else{
  
                localStorage.setItem('accessToken', data.accessToken);
                localStorage.setItem('user', JSON.stringify(data.userProfile));
                localStorage.removeItem('cartitems');
                localStorage.removeItem('NonLoginUserID');
                localStorage.removeItem('NonLoginaccessToken');
                if(data?.userProfile?.UserType == 4){
                    window.location.href = '/profile/information';
                }
                // else{
                //     window.location.href = '/';
                // }
                
                dispatch({ type: actionType.LOGIN_SUCCESS, payload: data.userProfile });
                dispatch({ type: actionType.AUTH_MODAL, payload: 0 });
            }

        }else{
            dispatch({ type: actionType.LOGIN_FAILURE, payload: data });
        }
    } catch (error) {
        dispatch({ type: actionType.LOGIN_FAILURE, payload: error });
    }
}










export const forgotPasswordAction = params => async dispatch => {
    dispatch({ type: actionType.LOGIN_REQIEST });
    dispatch({ type: actionType.TEMP_EMAIL, payload: params.email});
    try {
        let resData = await forgotPassword(params);
        let { status, data } = resHandle(resData);
        if(status){
            dispatch({ type: actionType.FORGOT_MODAL });
            dispatch({ type: actionType.SUCCESS_MODAL });
        }else{
            dispatch({ type: actionType.LOGIN_FAILURE, payload: data });
        }
    } catch (error) {
        dispatch({ type: actionType.LOGIN_FAILURE, payload: error });
    }
}










export const resetPasswordAction = params => async dispatch => {
    dispatch({ type: actionType.LOGIN_REQIEST });
    try {
        let resData = await resetPassword(params);
        let { status, data } = resHandle(resData);
        if(status){
            dispatch({ type: actionType.RESET_PASSWORD_MODAL });
            dispatch({ type: actionType.PASSWORD_SUCCESS_MODAL });
            dispatch({ type: actionType.LOGIN_FAILURE, payload: '' });
            return status;
        }else{
            dispatch({ type: actionType.LOGIN_FAILURE, payload: data });
        }
    } catch (error) {
        dispatch({ type: actionType.LOGIN_FAILURE, payload: error });
    }
}










export const resendOtpAction = params => async dispatch => {
    dispatch({ type: actionType.RESEND_OTP_EMAIL_LOADER });
    try {
        let resData = await resendOtp(params);
        let { status, data } = resHandle(resData);
        if(status){
            dispatch({ type: actionType.RESEND_OTP_EMAIL_LOADER });
        }else{
            dispatch({ type: actionType.RESEND_OTP_EMAIL_LOADER });
        }
    } catch (error) {
        dispatch({ type: actionType.RESEND_OTP_EMAIL_LOADER });
    }
}










export default userReducer;