import ApiInstance from './intercepter';
import API from '../config/api';

export const preList = params => ApiInstance.get(API.PRE_LIST, params);

export const camperList = () => ApiInstance.post(API.CAMPER_LIST);

export const filterPrograms = params => ApiInstance.post(API.ALL_PROGRAMS, params);

export const programFavourite = params => ApiInstance.post(API.PROGRAM_FAVOURITE, params);

export const viewProgramDetail = params => ApiInstance.get(API.VIEW_PROGRAM_DETAIL + params);

export const viewProgramByNameService = params => ApiInstance.get(API.VIEW_PROGRAM_BY_NAME + params);

export const getAllFavPrograms = params => ApiInstance.get(API.FAVOURITE_PROGRAMS, params) 

export const followcamps = params => ApiInstance.post(API.FOLLOW_CAMPS, params) 

export const addToCart = params => ApiInstance.post(API.ADD_TO_CART, params);

export const getCartItems = () => ApiInstance.get(API.GET_CART_ITEMS);

export const getCartProductsService = params => ApiInstance.get(API.GET_CART_ITEMS + params);

export const updateCartProductsService = (params) => ApiInstance.post(API.UPDATE_CART_ITEMS, params);

export const removeFromCart = (params) => ApiInstance.delete(API.REMOVE_FROM_CART+params);

export const confirmdetail = (params) => ApiInstance.post(API.CONFIRM_DETAILS, params);

export const confirmedlist = (params) => ApiInstance.get(API.CONFIRM_LIST, params);

export const validateSlotsService = (params) => ApiInstance.post(API.VALIDATE_SLOTS, params);

export const validateBookingService = (params) => ApiInstance.post(API.VALIDATE_BOOKING, params);

export const bookingCreate = (params) => ApiInstance.post(API.BOOKING_CREATE, params);

export const productBookingCreate = (params) => ApiInstance.post(API.PRODUCT_PURCHASE, params);

export const userBookingListForProductsService = (params) => ApiInstance.get(API.USER_BOOKING_LIST_FOR_PRODUCT + params);

export const adminBookingListForProductsService = (params) => ApiInstance.get(API.ADMIN_BOOKING_LIST_FOR_PRODUCTS + params);

export const itemPurchaseUpdateProductBooking = params => ApiInstance.post(API.ITEM_PURCHASE_UPDATE_PRODUCT_BOOKING, params);

export const bookingListService = (params) => ApiInstance.get(API.BOOKING_LIST + params);

export const bookingDetailService = (params) => ApiInstance.post(API.BOOKING_DETAIL, params);

export const getGalleryService = (params) => ApiInstance.get(API.GET_PROGRAM_GALLERY + params);

export const uploadGalleryService = params => ApiInstance.post(API.UPDATE_GALLERY_IMAGE, params);

export const camperListSelectCamperScreen = () => ApiInstance.post(API.CAMPER_LIST);

export const validatecoupon = (params) => ApiInstance.post(API.VALIDATE_COUPON, params);

export const validategiftcard = (params) => ApiInstance.get(API.VALIDATE_GIFTCODE + params);

export const createCustomerCardService = params => ApiInstance.post(API.CREATE_CUSTOMER_CARD, params);

export const deleteCardService = params => ApiInstance.post(API.DELETE_CARD, params);
export const deleteCustomerCardService = params => ApiInstance.post(API.DELETE_CUSTOMER_CARD, params);

export const getTaxService = () => ApiInstance.get(API.GET_TAXS);


export const getCustomerCardsService = params => ApiInstance.get(API.GET_CUSTOMER_PROFILE + params, {headers: {
  accessToken: localStorage.getItem('accessToken') ? localStorage.getItem('accessToken') : localStorage.getItem('NonLoginaccessToken') 
}});


export const getCustomerCardsService2 = params => ApiInstance.get(API.GET_CUSTOMER_PROFILE + params);

export const donateService = params => ApiInstance.post(API.DONATE, params);

export const userguest = () => ApiInstance.post(API.USER_GUEST);

export const addToCartNonLoginUser = (params) => ApiInstance.post(API.ADD_TO_CART, params, {headers: {
    accessToken:localStorage.getItem('NonLoginaccessToken') 
  }});

export const getCartItemsNonLoginUser = () => ApiInstance.get(API.GET_CART_ITEMS, {headers: {
  accessToken:localStorage.getItem('NonLoginaccessToken') 
}});  

export const removeFromCartNonLoginUser = (params) => ApiInstance.delete(API.REMOVE_FROM_CART+params,  {headers: {
  accessToken:localStorage.getItem('NonLoginaccessToken') 
}});  

export const camperListNonLoginUser = (params) => ApiInstance.post(API.CAMPER_LIST, params,{headers: {
  accessToken:localStorage.getItem('NonLoginaccessToken') 
}});  
export const confirmdetailNonLoginUser = (params) => ApiInstance.post(API.CONFIRM_DETAILS, params,{headers: {
  accessToken:localStorage.getItem('NonLoginaccessToken') 
}});  

export const confirmedlistNonLoginUser = (params) => ApiInstance.get(API.CONFIRM_LIST,{headers: {
  accessToken:localStorage.getItem('NonLoginaccessToken') 
}});  

export const bookingCreateNonLoginUser = (params) => ApiInstance.post(API.BOOKING_CREATE, params,{headers: {
  accessToken:localStorage.getItem('NonLoginaccessToken') 
}});

export const createCustomerCardServicenonLogin = params => ApiInstance.post(API.CREATE_CUSTOMER_CARD, params,{headers: {
  accessToken: localStorage.getItem('NonLoginaccessToken') 
}})


export const getCustomerCardsServiceNonLogin = () => ApiInstance.get(API.GET_CUSTOMER_PROFILE, {headers: {
  accessToken:localStorage.getItem('NonLoginaccessToken') 
}});

export const associatePrograms = (params) => ApiInstance.get(API.GET_ASSOCIAT_PROGRAMS + params);


export const bookingreview = (params) => ApiInstance.post(API.BOOKING_REVIEW , params);

export const getReview = params => ApiInstance.get(API.GET_REVIEW + params);

export const cloneProgramService = params => ApiInstance.post(API.CLONE_PROGRAM, params);

export const copyProgramItemsService = params => ApiInstance.post(API.COPY_PROGRAM_ITEMS, params);

export const getAllPrgramWithId = params => ApiInstance.get(API.GET_ALL_PRGRAM_WITH_ID + params);

export const uploadExcelService = params => ApiInstance.post(API.UPLOAD_EXCEL, params);

export const updateSerialNumberService = () => ApiInstance.get(API.UPDATE_SERIAL_NUMBER);

export const messageProgramService = params => ApiInstance.post(API.MESSAGE_PROGRAM, params);

export const messageProgramSingleService = params => ApiInstance.post(API.MESSAGE_PROGRAM_SINGLE, params);

export const cancelBookingService = params => ApiInstance.post(API.CANCEL_BOOKING, params);

export const cancelProgramBookingByCampers = params => ApiInstance.post(API.CANCEL_PROGRAM_BOOKING_BY_CAMPER, params);

export const updateBookingKeyService = params => ApiInstance.post(API.UPDATE_BOOKING_KEYS, params);

export const refundBookingByCampers = params => ApiInstance.post(API.REFUND_BOOKING_BY_CAMPER, params);

export const cancelRefundAction = params => ApiInstance.post(API.CANCEL_REFUND_ACTION, params);

export const camperCancelRefundAction = params => ApiInstance.post(API.CAMPERS_BOOKING_ACTION, params);


export const isMessageProgramExistService = params => ApiInstance.get(API.IS_MESSAGE_PROGRAM_EXIST + params);

export const getDistrictsService = params => ApiInstance.get(API.DISTRICT_LIST + params);
export const markPrimaryCard = (params) => ApiInstance.post(API.MARK_PRIMARY_CARD, params );

export const getProgramDetails = (params) => ApiInstance.get(API.VIEW_PROGRAM + params);

export const insuranceBookingCreate = (params) => ApiInstance.post(API.INSURANCE_PURCHASE, params);

export const additionalCharges = (params) => ApiInstance.post(API.CAMPER_ADDITIONAL_CHARGES, params);