import React, { Fragment, useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import moment from 'moment';
import { Empty, TimePicker } from 'antd';
import { Modal } from 'react-bootstrap';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import {
    getInboxService,
    getAllUserListService,
    createInboxGroupService,
    getChatHistoryService,
    getAllUserListForDirectorService,
} from '../../services/inboxService';
import { unreadCountAction } from '../../store/chat';
import {socket} from '../../services/socket';
import { MsgLoader } from '../reuse/SVG';
import { getHtml } from '../../helper';
import { CustomDatePicker } from '../reuse/FormControls';


const Inbox = props => {
    const chatScreen = document.getElementById('chat__screen');
    const scrollRef = useRef();
    let location                                           = useLocation();
    const [inputHeight, setInputHeight]                   = useState(0);
    const [users, setUsers]                               = useState([]);
    const [userSearch, setUserSearch]                     = useState('');
    const [userPageNo, setUserPageNo]                     = useState(1);
    const [createGroupModal, setCreateGroupModal]         = useState(false);
    const [createGroupstep, setCreateGroupstep]           = useState(1);
    const [newChatModal, setNewChatModal]                 = useState(false);
    const [inboxUser, setInboxUser]                       = useState([]);
    const [isSelected, setIsSelected]                     = useState(false);
    const [totalUserCount, setTotalUserCount]             = useState(0);
    const [singleUserId, setSingleUserId]                 = useState('');
    const [groupName, setGroupName]                       = useState('');
    const [groupImage, setGroupImage]                     = useState('');
    const [groupImageView, setGroupImageView]             = useState('');
    const [userId, setUserId]                             = useState([]);
    const [messages, setMessages]                         = useState([]);
    const [textMessage, setTextMessage]                   = useState('');
    const [newMessage, setNewMessage]                     = useState('');
    const [currentGroupId, setCurrentGroupId]             = useState('');
    const [currentChatUser, setCurrentChatUser]           = useState('');
    const [filterUser, setFilterUser]                     = useState('');
    const [msgLoader, setMsgLoader]                       = useState(false);
    const [lastMsgId, setLastMsgId]                       = useState('');
    const [msgCount, setMsgCount]                         = useState('');
    const [indexTab, setIndexTab]                         = useState(1);
    const [automationModal, setAutomationModal]           = useState(false);
    const [templateModal, setTemplateModal]               = useState(false);


    const [chatTab, setChatTab]                           = useState(2);
    const [a_msg, set_a_msg]                              = useState(2);
    const messageEl                                       = useRef();


    useEffect(() => {
        socket.emit('join'); 
        setNewMessage('');
        socket.on('receiveMessage', data => {
            setNewMessage(data);
        });
    }, []);


    

    useEffect(() => {
        if(currentGroupId){
            socket.emit('join', currentGroupId);         
        }

    }, [currentGroupId])




    useEffect(() => {
        if(newMessage.senderId == currentChatUser.userId){
            setMessages([...messages, newMessage]);
            setTimeout(() => {
                messageEl.current.scroll({ top: messageEl.current.scrollHeight, behavior: 'smooth' })
            }, 100)
        }
    }, [newMessage])



    const getInbox = () => {
        let query = `?search=${filterUser}`
        getInboxService(query).then(res => {
            if(res?.data?.statusCode){
                setInboxUser(res.data.responseData.result)
            }
        })
    }

    useEffect(() => {
        getInbox()
    }, [filterUser])






    const getAllUserList = () => {
        
        if(props.userProfile.UserType == 1){
            let query = `?search=${userSearch}&pageNo=${userPageNo}&limit=10`;
            getAllUserListService(query).then(res => {
                if(res?.data?.statusCode){
                    setTotalUserCount(res.data.responseData.count)
                    if(userSearch){
                        setUsers(res.data.responseData.result)
                    }else{
                        setUsers([...users, ...res.data.responseData.result])
                    }
                    
                }
            })

        }else {
            let query = `?search=${userSearch}&pageNo=${userPageNo}&limit=10&campCode=${props.userProfile.campCode}`;
            getAllUserListForDirectorService(query).then(res => {
                if(res?.data?.statusCode){
                    setTotalUserCount(res.data.responseData.count)
                    if(userSearch){
                        setUsers(res.data.responseData.result)
                    }else{
                        setUsers([...users, ...res.data.responseData.result])
                    }
                    
                }
            })
        }  
    }

    useEffect(() => {
        getAllUserList()
    }, [userSearch, userPageNo])


    const handleMsgChange = e => {
        setTextMessage(e.target.value)
        setInputHeight((e.target.value.match(/\n/g)||[]).length)
    }

    const toggleMsgSidebar = () => document.body.classList.toggle('toogle_msg_sider');


    const handleUserSerach = e => {
        setUserSearch(e.target.value);
        setUserPageNo(1);
    }


    const handleLoadMore = () => {
        setUserPageNo(userPageNo + 1);
    }

    const closeModal = () => {
        setCreateGroupModal(false);
        setNewChatModal(false);
        setCreateGroupstep(1);
        setUserSearch('');
        setUserPageNo(1);
        setAutomationModal(false);
        setTemplateModal(false);
    }



    useEffect(() => {
        if(props.bookingUserChat){
            handleSingleUserId(props.bookingUserChat)
        }

    }, [props.bookingUserChat])

    const sendMessage = () => {
        chatScreen.scrollTop = chatScreen.scrollHeight;
        if(textMessage.trim() != ''){

            setMessages([...messages, {
                message: textMessage,
                senderId: props.userProfile._id,
                timeStamp: new Date().getTime()
            }]); 

            setTimeout(() => {
                messageEl.current.scroll({ top: messageEl.current.scrollHeight, behavior: 'smooth' })
            }, 100)

            let tempMsg = {
                'groupId': currentChatUser.groupId,
                'receiverId': currentChatUser.userId,
                'senderId': props.userProfile._id,
                'message': textMessage,
                'timeStamp': new Date().getTime()
            }

            socket.emit('sendMessage', tempMsg);

            setTextMessage('');
            getInbox();

        }
    }





    const handleSingleUserId = user => {
        setCurrentChatUser(user);
        setSingleUserId(user._id)
        closeModal();
        setIsSelected(true);
        getMessagesDirect(user);
    }

    useEffect(() => {
        if(location.user){
            handleSingleUserId(location.user);
        } 
    }, [])

    const getMessagesDirect = (user) =>{
        setCurrentChatUser({
            name: user.name
        })
        setIsSelected(true);
        toggleMsgSidebar();
            let query = '';
            if(user.type==1)
            {
                query = `/?type=1&receiverId=${user._id}&limit=15`;
            }
            else
            {
                query = `/?type=2&groupId=${user._id}&limit=15`;
            }
            getChatHistoryService(query).then(res => {
            setMsgLoader(false);
            if(res?.data?.statusCode){
                setLastMsgId(res.data.responseData.result.length ? res.data.responseData.result[res.data.responseData.result.length - 1].chatId : '');
                setMessages(res.data.responseData.result.reverse());
            }
        })
    }

    const createGroup = () => {
        let params = new FormData();
        params.append('groupName',groupName);
        params.append('groupImage',groupImage);
        params.append('userId',JSON.stringify([...userId, props.userProfile._id]));
        params.append('type','2');

        createInboxGroupService(params).then(res => {
          if(res?.data?.statusCode){
            closeModal();
            getInbox();
          }
        })  
    }

    const handleFileChange = e => {
        let reader = new FileReader();
        let file = e.target.files[0];
        setGroupImage(file);
        
        reader.addEventListener('load', () => {
            setGroupImageView(reader.result);
        }, false);
        if (file) { reader.readAsDataURL(file) }
    }

    const addUserInGroup = e => {
        if(userId.includes(e.target.value)){
            let tempArr = userId.filter(item => item != e.target.value);
            setUserId(tempArr);
        }else{
            setUserId([...userId, e.target.value])
        }
    }

    

    const getMessages = user => {
        setMsgLoader(true);
        if(user.type == 1){
            setCurrentChatUser({
                ...user,
                name: user.userId[0].name,
                userId: user.userId[0]._id,
                profileImage: user.userId[0].profileImage
            })
        }else{
            setCurrentChatUser({
                name: user.groupName,
                profileImage: user.groupImage
            })
        }
        
        setIsSelected(true);
        setCurrentGroupId(user.groupId);
        toggleMsgSidebar();
            let query = `/?groupId=${user.groupId}&receiverId=${''}&limit=15`;
            getChatHistoryService(query).then(res => {
                setMsgLoader(false);
                if(res?.data?.statusCode){
                    setLastMsgId(res.data.responseData.result.length ? res.data.responseData.result[res.data.responseData.result.length - 1].chatId : '');
                    setMessages(res.data.responseData.result.reverse());
                    chatScreen.scrollTop = chatScreen.scrollHeight;
                    props.unreadCountAction();
                    getInbox();
                }
        })
    }




        
      const handleScroll = e => {
        let chatScroll = document.getElementById('chat__screen');
          if(chatScroll.scrollTop < 1 && msgCount !== 0){
            setMsgLoader(true);
            let query = `/?lastMsgId=${lastMsgId}&groupId=${currentGroupId}&receiverId=${''}&limit=15`;
            getChatHistoryService(query).then(res => {
                setMsgLoader(false);
                if(res?.data?.statusCode){
                    setMsgCount(res.data.responseData.count);
                    setLastMsgId(res.data.responseData.result.length ? res.data.responseData.result[res.data.responseData.result.length - 1].chatId : '');
                    let oldMsg = res.data.responseData.result.reverse();
                    setMessages([...oldMsg, ...messages]);
                }
            })
          }  
      };




      const handleScheduleDate = e => {
      }
  



    return(
        <div className="body_container cm_over_auto pb-4" style={{backgroundColor: '#F7F7F7'}}>
            <div className="container">
                <div className="d-flex align-items-center justify-content-between page_card_header">
                    <h4>Inbox</h4>

                    {/* {
                        props?.userProfile?.UserType == 2 ? <div className='inbox_tabs'>
                            <span className={indexTab == 1 ? 'active' : ''} onClick={() => setIndexTab(1)}>Conversations</span>
                            <span className={indexTab == 2 ? 'active' : ''} onClick={() => setIndexTab(2)}>Automated Action</span>
                            <span className={indexTab == 3 ? 'active' : ''} onClick={() => setIndexTab(3)}>Templates</span>
                        </div> : ''
                    } */}
                    
                    <div className="btn_group">
                        <button className="btn btn-outline-primary btn-sm rounded-0" onClick={() => setNewChatModal(true)}><i className="icon-create" /> New Message</button>
                        <button className="btn btn-secondary ml-3 btn-sm rounded-0" onClick={() => setCreateGroupModal(true)}><i className="icon-users" /> Organize Group</button>
                    </div>
                </div>
            <div className="page_card pb-0">


                {
                    indexTab == 1 ? (
                        <Fragment>
                            <div className="page_card_body p-0">
                                <div className="d-flex chat_wrapper">
                                    <div className="cht_users">
                                        <div className="contact_search">
                                            <i className="icon-search" />
                                            <input type="text" value={filterUser} onChange={e => setFilterUser(e.target.value)} placeholder="Search" />
                                        </div>


                                        {/* <div className='me_pro_user'>
                                            <img src={props?.userProfile?.profileImage || require('../../assets/img/user.png')} />
                                            <p>{props?.userProfile?.name}</p>
                                        </div> */}
{/* 
                                        <div className='chat_tabs'>
                                            <span className={chatTab == 1 ? 'active' : ''} onClick={() => setChatTab(1)}>Unread</span>
                                            <span className={chatTab == 2 ? 'active' : ''} onClick={() => setChatTab(2)}>Recents</span>
                                            <span className={chatTab == 3 ? 'active' : ''} onClick={() => setChatTab(3)}>Starred</span>
                                            <span className={chatTab == 4 ? 'active' : ''} onClick={() => setChatTab(4)}>All</span>
                                        </div> */}

                                        <ul>
                                            {
                                                inboxUser.length ? inboxUser.map(item => (
                                                    <li key={item.groupId} className="d-flex align-items-center" onClick={() => getMessages(item)}>
                                                    
                                                        {item.type == 1 ? item.userId?.length ? <Fragment>
                                                            <img className="chat_user_img" src={item.userId[0].profileImage ? item.userId[0].profileImage : require('../../assets/img/user.png')} />
                                                            <div>
                                                                <p>{item.userId[0].name} {item.count ? <span className="msg_count">{item.count}</span> : ''}</p>
                                                            </div>
                                                        </Fragment> : '' : ''}


                                                        {item.type == 2 ? <Fragment>
                                                            <img className="chat_user_img" src={item.groupImage ? item.groupImage : require('../../assets/img/user.png')} />
                                                        <p>{item.groupName}</p>
                                                        </Fragment> : ''}

                                            
                                                    </li>
                                                )) : <li className="cm_empty"><Empty description="Data Not Found" /></li>
                                            }
                                        </ul>

                                    </div>




                                    <div className={`d-flex w-100 flex-column chat_box ${isSelected ? '' : 'd_none'}`}>
                                        <div className="chat_mob_trigger" onClick={toggleMsgSidebar}><i className="icon-search" /> Search</div>
                                        <div className="mb-auto d-flex align-items-center chat_user_head">
                                            <img className="chat_user_img" src={currentChatUser.profileImage ? currentChatUser.profileImage : require("../../assets/img/user.png")} />
                                            <p><b className="d-block">{currentChatUser.name}</b></p>
                                        </div>


                                        <div id="chat__screen" ref={messageEl} onScroll={handleScroll} className={`chat_screen ${msgLoader ? 'no_scroll' : ''}`}>
                                            {msgLoader ? <div className="msg_loader"><MsgLoader /></div> : ''}
                                            <div className="chat_msgs">



                                            {
                                                
                                                messages.length ? messages.map((message, i) => (
                                                <React.Fragment key={i}>                                  
                                                {
                                                    message.senderId == props.userProfile._id ? 
                                                    <div key={message.timeStamp} className="chat_msg me_user">
                                                        <div className="cm_name_m"><time>{moment(message.timeStamp).format('hh:mm a, (MMM Do YYYY)')}</time></div>
                                                        {/* <p>{message.message}</p> */}
                                                        <div className="msg_text">{message.message ? getHtml(message.message) : ''} </div>
                                                    </div>
                                                    :
                                                    <div key={message.timeStamp}  className="chat_msg other_user">
                                                        <img className="chat_user_img" src={message.profileImage ? message.profileImage : require("../../assets/img/user.png")} />
                                                        <div className="cm_name_m">{message.name} <time>{moment(message.timeStamp).format('hh:mm a, (MMM Do YYYY)')}</time></div>
                                                        <div className="msg_text">{message.message ? getHtml(message.message) : ''} </div>
                                                    </div>

                                                }
                                                </React.Fragment>
                                            )) : ''
                                            }
                                            </div>
                                        </div>


                                        <div className="mt-auto chat_text_area">
                                            <textarea
                                                onChange={handleMsgChange}
                                                value={textMessage}
                                                onKeyPress={e => {
                                                    if (e.key === 'Enter') {
                                                        e.preventDefault();
                                                        sendMessage()
                                                    }
                                                }}
                                                className="form-control"
                                                style={{height: `${inputHeight * 30}px`}}
                                                placeholder="type a message"
                                            ></textarea>
                                            <span className="send_btn" onClick={sendMessage}><i className="icon-send" /></span>
                                        </div>
                                    </div>

                                    

                                    <div className={`chat_welcom_screen ${isSelected ? 'd_none' : ''}`}>
                                        <div className="chat_welcom_inner">
                                            <img src={props.userProfile?.profileImage ? props.userProfile?.profileImage : require('../../assets/img/user.png')} alt={props.userProfile?.name} />
                                            <h4>Welcome back {props.userProfile?.name}</h4>
                                        </div>
                                    </div>



                                </div>
                            </div>
                        </Fragment>
                    ) : ''
                }











                {
                    indexTab == 2 ? (
                        <Fragment>
                            <div className="page_card_body p-0">
                                <div className="d-flex justify-content-between p-3">
                                    <span>Automated Actions (1) template</span>
                                    <span onClick={() => setAutomationModal(true)} className='btn btn-secondary btn-sm'>Add Automation</span>
                                </div>
                                    
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th>S.No</th>
                                            <th>Name</th>
                                            <th>Template</th>
                                            <th>Trigger</th>
                                            <th>Type</th>
                                            <th>Programs</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>1</td>
                                            <td>Camp Reminder</td>
                                            <td>Tamplate Z intro</td>
                                            <td>2 weeks Prior</td>
                                            <td>SMS, Email</td>
                                            <td>All</td>
                                        </tr>
                                    
                                    </tbody>
                                </table>
                            </div>
                        </Fragment>
                    ) : ''
                }









                {
                    indexTab == 3 ? (
                        <Fragment>
                            <div className="page_card_body p-0">
                                <div className="d-flex justify-content-between p-3">
                                    <span>Automated Actions (1) template</span>
                                    <span onClick={() => setTemplateModal(true)} className='btn btn-secondary btn-sm'>Add Template</span>
                                </div>
                                    
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th>S.No</th>
                                            <th>Name</th>
                                            <th>Body</th>
                                            <th>Attachments</th>
                                            <th>Type</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>1</td>
                                            <td>Template Z intro</td>
                                            <td>Welcome...</td>
                                            <td>1</td>
                                            <td></td>
                                        </tr>
                                    
                                    </tbody>
                                </table>
                            </div>
                        </Fragment>
                    ) : ''
                }
                    

            </div>






            <Modal
                size="md"
                show={templateModal}
                dialogClassName="remove_header_line"
                animation={false}
                centered>

                <Modal.Header>
                    <Modal.Title>Create Template</Modal.Title>
                    <span className="modal_close" onClick={closeModal}><i className="icon-cross" /></span>
                </Modal.Header>

                <Modal.Body>
                
                    <div className="form-group">
                        <input
                            type="text"
                            className='form-control'
                            placeholder="Name" />
                    </div>


                    <div className="form-group">
                        <input
                            type="text"
                            className='form-control'
                            placeholder="Subject" />
                    </div>

                    
                    <div className="form-group">
                        <p className='a_label'>Body</p>
                        <ReactQuill
                            className={`inbox_editer ${0 ? 'is-invalid' : ''}`}
                            theme="snow"
                            value={a_msg}
                            onChange={e => set_a_msg(e)} />
                    </div>


                    <div className="form-group">
                        <p className='a_label'>Attech File</p>
                    </div>





                    <div className="text-center">
                        <button className='btn btn-primary pl-5 pr-5'>Create</button>
                    </div>

                </Modal.Body>
            </Modal>





            <Modal
                size="lg"
                show={automationModal}
                dialogClassName="remove_header_line"
                animation={false}
                centered>

                <Modal.Header>
                    <Modal.Title>Create Automation</Modal.Title>
                    <span className="modal_close" onClick={closeModal}><i className="icon-cross" /></span>
                </Modal.Header>

                <Modal.Body>
                
                            <form className='p-4'>
                                    <div className="row">
                                        <div className="col-lg-5">

                                        {/* <div className="check_box_wrapper cm_dark_check mb-3">
                                            <input
                                                type="radio"
                                                name="1"
                                                value="1" />
                                            <span className="check_box"><span></span> To Users</span>
                                        </div>
                                        <div className="check_box_wrapper cm_dark_check mb-3">
                                        <input
                                                type="checkbox"
                                                name="2"
                                                value="2" />
                                            <span className="check_box"><span></span> To Programs</span>
                                        </div> */}


                                            <div className="form-group">
                                                <p className="a_label">Message Type</p>
                                                <div className="check_box_wrapper cm_dark_check mb-3">
                                                    <input
                                                        type="checkbox"
                                                        name="1"
                                                        value="1" />
                                                    <span className="check_box"><span></span> Phone Text</span>
                                                </div>
                                                <div className="check_box_wrapper cm_dark_check mb-3">
                                                <input
                                                        type="checkbox"
                                                        name="2"
                                                        value="2" />
                                                    <span className="check_box"><span></span> App Message</span>
                                                </div>
                                                <div className="check_box_wrapper cm_dark_check mb-3">
                                                <input
                                                        type="checkbox"
                                                        name="3"
                                                        value="3" />
                                                    <span className="check_box"><span></span> Email</span>
                                                </div>
                                                <div className="check_box_wrapper cm_dark_check mb-3">
                                                    <input
                                                        type="checkbox"
                                                        name="saveAsDefault" />
                                                    <span className="check_box"><span></span> Save above as default</span>
                                                </div>


                                                
                                            </div>
                                        </div>
                                        <div className="col-lg-7">
                                            <p className="a_label">Schedule Send for Later</p>

                                            <div className='row'>
                                                <div className='col'>
                                                    <div className="form-group">
                                                        <label>Date</label>
                                                        <CustomDatePicker
                                                            error={''}
                                                            disabledDate={current => current && current < moment.utc(new Date().getTime() - 86400000 )}
                                                            change={handleScheduleDate} />
                                                    </div>
                                                </div>
                                                <div className='col'>
                                                    <div className="form-group">
                                                        <label>Time</label>
                                                        <TimePicker
                                                            use12Hours
                                                            format="h:mm a"
                                                            className={`form-control`}/>
                                                    </div>
                                                </div>
                                            </div>


                                            <div className="form-group">
                                                <label>Timezone</label>
                                                <select className='form-control' name="timezone">
                                                    <option></option>
                                                    <option value="Etc/GMT+12">(GMT-12:00) International Date Line West</option>
                                                    <option value="Pacific/Midway">(GMT-11:00) Midway Island, Samoa</option>
                                                    <option value="Pacific/Honolulu">(GMT-10:00) Hawaii</option>
                                                    <option value="US/Alaska">(GMT-09:00) Alaska</option>
                                                    <option value="America/Los_Angeles">(GMT-08:00) Pacific Time (US & Canada)</option>
                                                    <option value="America/Tijuana">(GMT-08:00) Tijuana, Baja California</option>
                                                    <option value="US/Arizona">(GMT-07:00) Arizona</option>
                                                    <option value="America/Chihuahua">(GMT-07:00) Chihuahua, La Paz, Mazatlan</option>
                                                    <option value="US/Mountain">(GMT-07:00) Mountain Time (US & Canada)</option>
                                                    <option value="America/Managua">(GMT-06:00) Central America</option>
                                                    <option value="US/Central">(GMT-06:00) Central Time (US & Canada)</option>
                                                    <option value="America/Mexico_City">(GMT-06:00) Guadalajara, Mexico City, Monterrey</option>
                                                    <option value="Canada/Saskatchewan">(GMT-06:00) Saskatchewan</option>
                                                    <option value="America/Bogota">(GMT-05:00) Bogota, Lima, Quito, Rio Branco</option>
                                                    <option value="US/Eastern">(GMT-05:00) Eastern Time (US & Canada)</option>
                                                    <option value="US/East-Indiana">(GMT-05:00) Indiana (East)</option>
                                                    <option value="Canada/Atlantic">(GMT-04:00) Atlantic Time (Canada)</option>
                                                    <option value="America/Caracas">(GMT-04:00) Caracas, La Paz</option>
                                                    <option value="America/Manaus">(GMT-04:00) Manaus</option>
                                                    <option value="America/Santiago">(GMT-04:00) Santiago</option>
                                                    <option value="Canada/Newfoundland">(GMT-03:30) Newfoundland</option>
                                                    <option value="America/Sao_Paulo">(GMT-03:00) Brasilia</option>
                                                    <option value="America/Argentina/Buenos_Aires">(GMT-03:00) Buenos Aires, Georgetown</option>
                                                    <option value="America/Godthab">(GMT-03:00) Greenland</option>
                                                    <option value="America/Montevideo">(GMT-03:00) Montevideo</option>
                                                    <option value="America/Noronha">(GMT-02:00) Mid-Atlantic</option>
                                                    <option value="Atlantic/Cape_Verde">(GMT-01:00) Cape Verde Is.</option>
                                                    <option value="Atlantic/Azores">(GMT-01:00) Azores</option>
                                                    <option value="Africa/Casablanca">(GMT+00:00) Casablanca, Monrovia, Reykjavik</option>
                                                    <option value="Etc/Greenwich">(GMT+00:00) Greenwich Mean Time : Dublin, Edinburgh, Lisbon, London</option>
                                                    <option value="Europe/Amsterdam">(GMT+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna</option>
                                                    <option value="Europe/Belgrade">(GMT+01:00) Belgrade, Bratislava, Budapest, Ljubljana, Prague</option>
                                                    <option value="Europe/Brussels">(GMT+01:00) Brussels, Copenhagen, Madrid, Paris</option>
                                                    <option value="Europe/Sarajevo">(GMT+01:00) Sarajevo, Skopje, Warsaw, Zagreb</option>
                                                    <option value="Africa/Lagos">(GMT+01:00) West Central Africa</option>
                                                    <option value="Asia/Amman">(GMT+02:00) Amman</option>
                                                    <option value="Europe/Athens">(GMT+02:00) Athens, Bucharest, Istanbul</option>
                                                    <option value="Asia/Beirut">(GMT+02:00) Beirut</option>
                                                    <option value="Africa/Cairo">(GMT+02:00) Cairo</option>
                                                    <option value="Africa/Harare">(GMT+02:00) Harare, Pretoria</option>
                                                    <option value="Europe/Helsinki">(GMT+02:00) Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius</option>
                                                    <option value="Asia/Jerusalem">(GMT+02:00) Jerusalem</option>
                                                    <option value="Europe/Minsk">(GMT+02:00) Minsk</option>
                                                    <option value="Africa/Windhoek">(GMT+02:00) Windhoek</option>
                                                    <option value="Asia/Kuwait">(GMT+03:00) Kuwait, Riyadh, Baghdad</option>
                                                    <option value="Europe/Moscow">(GMT+03:00) Moscow, St. Petersburg, Volgograd</option>
                                                    <option value="Africa/Nairobi">(GMT+03:00) Nairobi</option>
                                                    <option value="Asia/Tbilisi">(GMT+03:00) Tbilisi</option>
                                                    <option value="Asia/Tehran">(GMT+03:30) Tehran</option>
                                                    <option value="Asia/Muscat">(GMT+04:00) Abu Dhabi, Muscat</option>
                                                    <option value="Asia/Baku">(GMT+04:00) Baku</option>
                                                    <option value="Asia/Yerevan">(GMT+04:00) Yerevan</option>
                                                    <option value="Asia/Kabul">(GMT+04:30) Kabul</option>
                                                    <option value="Asia/Yekaterinburg">(GMT+05:00) Yekaterinburg</option>
                                                    <option value="Asia/Karachi">(GMT+05:00) Islamabad, Karachi, Tashkent</option>
                                                    <option value="Asia/Calcutta">(GMT+05:30) Chennai, Kolkata, Mumbai, New Delhi</option>
                                                    <option value="Asia/Calcutta">(GMT+05:30) Sri Jayawardenapura</option>
                                                    <option value="Asia/Katmandu">(GMT+05:45) Kathmandu</option>
                                                    <option value="Asia/Almaty">(GMT+06:00) Almaty, Novosibirsk</option>
                                                    <option value="Asia/Dhaka">(GMT+06:00) Astana, Dhaka</option>
                                                    <option value="Asia/Rangoon">(GMT+06:30) Yangon (Rangoon)</option>
                                                    <option value="Asia/Bangkok">(GMT+07:00) Bangkok, Hanoi, Jakarta</option>
                                                    <option value="Asia/Krasnoyarsk">(GMT+07:00) Krasnoyarsk</option>
                                                    <option value="Asia/Hong_Kong">(GMT+08:00) Beijing, Chongqing, Hong Kong, Urumqi</option>
                                                    <option value="Asia/Kuala_Lumpur">(GMT+08:00) Kuala Lumpur, Singapore</option>
                                                    <option value="Asia/Irkutsk">(GMT+08:00) Irkutsk, Ulaan Bataar</option>
                                                    <option value="Australia/Perth">(GMT+08:00) Perth</option>
                                                    <option value="Asia/Taipei">(GMT+08:00) Taipei</option>
                                                    <option value="Asia/Tokyo">(GMT+09:00) Osaka, Sapporo, Tokyo</option>
                                                    <option value="Asia/Seoul">(GMT+09:00) Seoul</option>
                                                    <option value="Asia/Yakutsk">(GMT+09:00) Yakutsk</option>
                                                    <option value="Australia/Adelaide">(GMT+09:30) Adelaide</option>
                                                    <option value="Australia/Darwin">(GMT+09:30) Darwin</option>
                                                    <option value="Australia/Brisbane">(GMT+10:00) Brisbane</option>
                                                    <option value="Australia/Canberra">(GMT+10:00) Canberra, Melbourne, Sydney</option>
                                                    <option value="Australia/Hobart">(GMT+10:00) Hobart</option>
                                                    <option value="Pacific/Guam">(GMT+10:00) Guam, Port Moresby</option>
                                                    <option value="Asia/Vladivostok">(GMT+10:00) Vladivostok</option>
                                                    <option value="Asia/Magadan">(GMT+11:00) Magadan, Solomon Is., New Caledonia</option>
                                                    <option value="Pacific/Auckland">(GMT+12:00) Auckland, Wellington</option>
                                                    <option value="Pacific/Fiji">(GMT+12:00) Fiji, Kamchatka, Marshall Is.</option>
                                                    <option value="Pacific/Tongatapu">(GMT+13:00) Nuku'alofa</option>
                                                    </select>
                                            </div>


                                            

                                            
                                        </div>
                                    </div>


                                    <div className="form-group">
                                        <div className='d-flex justify-content-sm-between align-items-center mb-3'>
                                            <p className='a_label mb-0'>Message</p>
                                           <div className='w-25'>
                                            <select placeholder='Select Template' className='form-control' name="timezone">
                                                    <option>Select Template</option>
                                                    <option value="Etc/GMT+12">Template 1</option>
                                                    <option value="Etc/GMT+12">Template 2</option>
                                                    <option value="Etc/GMT+12">Template 3</option>
                                                    <option value="Etc/GMT+12">Template 4</option>
                                                    <option value="Etc/GMT+12">Template 5</option>
                                                </select>
                                           </div>
                                        </div>
                                        <ReactQuill
                                            className={`inbox_editer ${0 ? 'is-invalid' : ''}`}
                                            theme="snow"
                                            value={a_msg}
                                            onChange={e => set_a_msg(e)} />
                                    </div>
                                    



                        

                                    <div className="text-center">
                                        <button Send className='btn btn-primary pl-5 pr-5'>Send</button>
                                    </div>
                                </form>

                </Modal.Body>
            </Modal>















            <Modal
                size="lg"
                show={newChatModal}
                dialogClassName="remove_header_line"
                animation={false}
                centered>

                <Modal.Header>
                    <Modal.Title>New Chat</Modal.Title>
                    <span className="modal_close" onClick={closeModal}><i className="icon-cross" /></span>
                </Modal.Header>

                <Modal.Body>
                
                    <div className="search_wrapper">
                        <i className="icon-search" />
                        <input
                            type="text"
                            value={userSearch}
                            onChange={handleUserSerach}
                            placeholder="Search" />
                    </div>
                    
                    <div className="user_list single_user">
                        <ul>
                            {
                                users.length ? users.filter(item => item._id != props.userProfile._id).map(user => (
                                    <li key={user._id} onClick={() => handleSingleUserId(user)}>
                                        <img src={user.profileImage ? user.profileImage : require("../../assets/img/user.png")} />
                                        <p>{user.name ? user.name : user.email}</p>
                                        {/* {user.UserType == 2 ? <small className='ml-2'> Camp Director</small> : ''} */}
                                    </li>
                                )) : ''
                            }
                        </ul>

                        {
                            (totalUserCount == users.length || (totalUserCount - users.length) > 0) ? 
                            <div className="text-center position-relative mt-4">
                                <button onClick={handleLoadMore} className="btn btn-primary btn-sm">Load more</button>
                                <div className="remain_user">{totalUserCount - users.length} more users</div>
                            </div> : ''
                        }
                        
                    </div>

                </Modal.Body>
            </Modal>


            <Modal
                size="lg"
                show={createGroupModal}
                dialogClassName="remove_header_line"
                animation={false}
                centered>

                <Modal.Header>
                    <Modal.Title>Create Group</Modal.Title>
                    <span className="modal_close" onClick={closeModal}><i className="icon-cross" /></span>
                </Modal.Header>

                <Modal.Body>
                
                {
                    (createGroupstep === 1) ? <Fragment>
                        <div className="search_wrapper">
                            <i className="icon-search" />
                            <input
                                type="text"
                                value={userSearch}
                                onChange={handleUserSerach}
                                placeholder="Search" />
                        </div>
                        
                        <div className="user_list">
                            <ul>
                                {
                                    users.length ? users.map(user => (
                                        <li key={user._id}>
                                            <img src={user.profileImage ? user.profileImage : require("../../assets/img/user.png")} />
                                            <p>{user.name}</p>
                                            <div className="check_box_wrapper cm_dark_check pt-1">
                                            <input type="checkbox" value={user._id} checked={userId.includes(user._id)} onChange={addUserInGroup} />
                                                <span className="check_box"><span></span></span>
                                            </div>
                                        </li>
                                    )) : ''
                                }  
                            </ul>

                            {
                                (totalUserCount == users.length || (totalUserCount - users.length) > 0) ? 
                                <div className="text-center position-relative mt-4">
                                    <button onClick={handleLoadMore} className="btn btn-primary btn-sm">Load more</button>
                                    <div className="remain_user">{totalUserCount - users.length} more users</div>
                                </div> : ''
                            }
                        </div>
                    </Fragment>
                    
                    :

                    <Fragment>
                        <div className="icon_upload_wrapper" style={{width: '100%'}}>
                            <input type="file" onChange={handleFileChange} accept="image/*" />
                            {groupImageView ? <img src={groupImageView} /> : ''}

                            {groupImageView ? <i className="icon-create" /> : <div>
                                                                                <i className="icon-upload-cercle" />
                                                                                <p>Upload Image</p>
                                                                            </div>}
                            
                        </div>

                        <div className="group_name">
                            <input
                                type="text"
                                value={groupName}
                                onChange={e => setGroupName(e.target.value)}
                                placeholder="Group name" />
                        </div>

                    </Fragment>
                }



                    <div className="d-flex justify-content-center">
                        <button onClick={closeModal} className="btn btn-light mr-4 pl-5 pr-5">Cancel</button>
                        {
                            createGroupstep == 1 ?
                            <button disabled={userId.length ? false : true} onClick={() => setCreateGroupstep(2)} className="btn btn-primary pl-5 pr-5">Next</button>:
                            <button disabled={groupName != '' ? false : true} onClick={createGroup} className="btn btn-primary pl-5 pr-5">Done</button>
                        }
                    </div>


                </Modal.Body>
            </Modal>


        </div>
        </div>
    )
}


const mapStateToProps = state => {
    let { userProfile } = state.user;
    let { bookingUserChat } = state.chat;
    return {
        userProfile,
        bookingUserChat,
    };
}
  
const mapDispatchToProps = dispatch => ({
    unreadCountAction: () => dispatch(unreadCountAction()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Inbox);