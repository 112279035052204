import React, {useState, useEffect,useCallback} from 'react';
import { DatePicker, Select, notification } from 'antd';
import { useHistory, useLocation } from 'react-router-dom';
import moment from 'moment';
import { connect } from 'react-redux';
import PhoneInput from 'react-phone-number-input/input';
import {profileInformation, updateProfile, deleteCamper} from '../../services/campersServices';
import {ProgramLoader} from '../reuse/SVG';
import { removeTime, genders, resHandle, gendersTwo} from '../../helper';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { upadteUserProfileAction } from '../../store/user';

import DateSelector from '../date/DateSelector';



const dateFormatNew = 'MM/DD/YYYY';

const ProfileEdit = props => {
    let history = useHistory();
    let location = useLocation();

    const[name, setName]                                        = useState('');
    const[nameErr, setNameErr]                                  = useState('');

    const[lastName, setLastName]                                = useState('');
    const[lastNameErr, setLastNameErr]                          = useState('');

    const[email, setEmail]                                      = useState('');

    const[phone, setPhoneNumber]                                = useState('');
    const[phoneErr, setPhoneNumberErr]                          = useState('');

    const[dob, setDob]                                          = useState(1349634600000);
    const[dobErr, setDobErr]                                    = useState('');

    const[address, setAddress]                                  = useState('');
    const[addressErr, setAddressErr]                            = useState('');

    const[gender, setGender]                                    = useState('');
    const[genderErr, setGenderErr]                              = useState('');

    const[loader, setLoader]                                    = useState(true);
    const[profileImage, setProfileImage]                        = useState();
    const[profileimageurl, setProfileImageurl]                  = useState();
    const[btnloder, setBtnLoader]                               = useState(false);
    const[userCampersList, setUserCamperList]                   = useState([]);

    const[secondaryPhoneNumber, setSecondaryPhoneNumber]        = useState();
    const[secondaryContact, setSecondaryContact]                = useState();
    const[secondaryEmail, setSecondaryEmail]                    = useState('');
    const[cartStoreData, setCartStoreData]                      = useState({});


    




    useEffect(() => {
        profileInformation().then(res => {
            if(res.data.statusCode == 1){
                let profileInfo = res.data.responseData.result;
                setName(profileInfo.name);
                setEmail(profileInfo.email);
                setDob(profileInfo.dob);
                setGender(profileInfo.gender);
                setUserCamperList(profileInfo.campersData);
                if(profileInfo.phone!==undefined){setPhoneNumber(profileInfo.phone)}
                if(profileInfo.lastName!==undefined){setLastName(profileInfo.lastName)}
                if(profileInfo.profileImage!==undefined){setProfileImageurl(profileInfo.profileImage)}
                if(profileInfo.secondaryContact!==undefined){setSecondaryContact(profileInfo.secondaryContact)}
                if(profileInfo.secondaryPhoneNumber!==undefined){setSecondaryPhoneNumber(profileInfo.secondaryPhoneNumber)}
                if(profileInfo.address!==undefined){setAddress(profileInfo.address)}
                if(profileInfo.secondaryEmail!==undefined){setSecondaryEmail(profileInfo.secondaryEmail)}
                setLoader(false);
            }
            else{
                setLoader(false);  
            }
        });


        let ddddd = localStorage.getItem('cart_store_data');
        if(ddddd){
            setCartStoreData(JSON.parse(ddddd))
        }
    },[])


    const handelChange = (e) =>{
        let name = e.target.name;
        let value = e.target.value;
        if(name=='name'){setName(value); setNameErr(false)}    
        if(name=='secondaryContact'){setSecondaryContact(value)}    
        if(name=='address'){setAddress(value)} 
    }

    const handelPhoneNumber = (e) =>{
        setPhoneNumber(e);
        setPhoneNumberErr('');

    }


    const handleValidate = () => {
        let validate = true;

        if(name == '' || name == undefined){
            setNameErr('Name is required');
            validate = false
        }

        if(lastName == '' || lastName == undefined){
            setLastNameErr('Last name is required');
            validate = false
        }

        if(lastName == '' || lastName == undefined){
            setLastNameErr('Last name is required');
            validate = false
        }

        if(gender == '' || gender == undefined){
            setGenderErr('Gender is required');
            validate = false
        }

        if(phone == '' || phone == undefined){
            setPhoneNumberErr('Phone number is required');
            validate = false
        }
        if(dobErr !== ''){
            validate = false
        }

        if(address == '' || address == undefined){
            setAddressErr('Address is required');
            validate = false
        }


        return validate;
    }



    const handelSubmit = (e) => {
        e.preventDefault();
        if(handleValidate()){

            setBtnLoader(true);
            let form_data = new FormData();
            form_data.append('profileImage',profileImage)
            form_data.append('name',name);
            form_data.append('lastName',lastName)
            form_data.append('phone',phone)
            form_data.append('gender',gender)
            form_data.append('secondaryContact',secondaryContact)
            form_data.append('secondaryPhoneNumber',secondaryPhoneNumber)
            form_data.append('secondaryEmail',secondaryEmail)
                        form_data.append('dob',dob)
            form_data.append('address',address);
            form_data.append('userId',props.userProfile._id);
            updateProfile(form_data).then(res=>{
                let { status, data } = resHandle(res);
                   if(status){
                    let temp_user = JSON.parse(localStorage.getItem('user'));
                    localStorage.setItem('user', JSON.stringify({...temp_user, ...data.result}));
                    props.upadteUserProfileAction(data.result);

                    if(props.itemsincart && location?.state?.cart_id){
                        history.push({
                            pathname: '/selectcampers', state: {
                                "numbderofCampers": 1,
                                "cart_id": location.state.cart_id,
                                "additionalexpadded": [],
                                "selectedcamperList": [props.userProfile._id],
                                "is_checkboxChecked": true
                            }
                        });

                        setTimeout(() => {
                            window.location.reload();
                        }, 1000)
                    }
                    
                   }
                setBtnLoader(false);

                
                notification.open({
                    message: 'Profile',
                    description:
                      'Profile Updated Successfully',
                });

                if(cartStoreData.isFirstTimeCart){
                    history.push({
                        pathname: '/selectcampers',
                        state: {
                            "numbderofCampers": cartStoreData.numbderofCampers,
                            "cart_id": cartStoreData.cart_id,
                            "additionalexpadded": [],
                            "selectedcamperList": [props.userProfile._id],
                        }
                    });

                    localStorage.removeItem('cart_store_data');
                }
            })

        }
    }

    const upload_image = (e) =>{
        if (e.target.files[0]) {
            setProfileImage(e.target.files[0]);
            const reader = new FileReader();
            reader.addEventListener("load", () => {
                setProfileImageurl(reader.result);
            });
            reader.readAsDataURL(e.target.files[0]);
          }
    }


    

  const handleDateChange = (timestamp) => {

    const date = new Date(timestamp);

    var timestamps = date.getTime();

   setDob(timestamps);


   
  };

  const disabledDate = (date) => {
     return date.getTime() > Date.now();
  };

 

    return(

        <React.Fragment>
              {loader ? <div className="page_loader"><ProgramLoader /></div> : ''}
            <div className="card mb-4">
                <h4 className="text-center">User Information</h4>
                <form onSubmit={handelSubmit} encType="multipart/form-data">       
                <div className="profile_upload">
                    <img src={profileimageurl ? profileimageurl : require('../../assets/img/camps-user.png')} />
                    <input onChange={upload_image} type="file" accept="image/x-png,image/gif,image/jpeg"/>
                    <i className="icon-camera-plus" />
                </div>

                <div className="row">
                    <div className="col-lg-1" />
                    <div className="col-lg-10">
                    
                        <div className="row">
                                <div className="col-md-6 form-group">
                                    <input
                                        type="text"
                                        name="name"
                                        onChange={e => (setName(e.target.value), setNameErr(''))}
                                        value={name}
                                        className={`form-control ${ nameErr ? 'is-invalid' : '' }`}
                                        placeholder="First Name" />

                                        {nameErr ? <OverlayTrigger placement="left" overlay={<Tooltip>{nameErr}</Tooltip>}><i className="input_info" /></OverlayTrigger> : ''}
                                </div>
 
                                <div className="col-md-6 form-group">
                                    <input
                                        type="text"
                                        value={lastName}
                                        onChange={e => (setLastName(e.target.value), setLastNameErr(''))}
                                        className={`form-control ${ lastNameErr ? 'is-invalid' : '' }`}
                                        placeholder="Last Name" />

                                        {lastNameErr ? <OverlayTrigger placement="left" overlay={<Tooltip>{lastNameErr}</Tooltip>}><i className="input_info" /></OverlayTrigger> : ''}
                                </div>


                                <div className="col-md-12 d-flex cm_gendr pro_wrapper">
                                    <label>Sex</label>
                                    <ul className="d-flex">
                                        {
                                            gendersTwo.map(item => (
                                                <li className="mr-2 check_box_wrapper">
                                                    <input
                                                        type="checkbox"
                                                        name={item.label}
                                                        checked={gender == item.value ? 'checked': ''}
                                                        value={item.value}
                                                        onChange={e => (setGender(item.value), setGenderErr(''))}
                                                    />
                                                    <span className="check_box"><span /> {item.label}</span> 
                                                </li>
                                            ))
                                        }

                                    </ul>
                                    
                                </div>     
                                <div className='col-md-12'>
                                {genderErr ? <p><span className='cm_err'>{genderErr}</span></p> : ''}   
                                </div>                        


                                <div className="col-md-6 form-group">
                                    <input
                                        type="email"
                                        value={email}
                                        className="form-control"
                                        placeholder="Email"
                                        readOnly />
                                </div>


                                <div className="col-md-6 form-group">
                                <div className="cm_phone_field cm_phone_field_2">
                                        <img className="us_flag" src={require('../../assets/img/united-states-flag-icon.svg')} />
                                        <PhoneInput
                                            country="US"
                                            placeholder="Phone number"
                                            className={`form-control ${ phoneErr ? 'is-invalid' : '' }`}
                                            onChange={handelPhoneNumber}
                                            maxLength={16}
                                            value={phone}
                                            name="phone" />

                                            {phoneErr ? <OverlayTrigger placement="left" overlay={<Tooltip>{phoneErr}</Tooltip>}><i className="input_info" /></OverlayTrigger> : ''}
                                    </div>
                                </div>


                                <div className="col-md-6 form-group">
                                    <h6>DOB</h6>
                              
                                        <DateSelector
                                            dateValue={dob}
                                            onDateChange={handleDateChange}
                                            disabledDate={disabledDate}
                                            dobErr={dobErr}
                                            setDobErr={setDobErr}
                                        /> 
                                        {dobErr ? <OverlayTrigger placement="left" overlay={<Tooltip>{dobErr}</Tooltip>}><i className="input_info" /></OverlayTrigger> : ''}
                                </div>


                                <div className="col-md-6 form-group">
                                <h6>Address</h6>
                                    <input
                                        type="text"
                                        name="address"
                                        onChange={e => (setAddress(e.target.value), setAddressErr(''))}
                                        value={address.trim() ? address : '' }
                                        className={`form-control ${ addressErr ? 'is-invalid' : '' }`}
                                        placeholder="Address" />

                                        {addressErr ? <OverlayTrigger placement="left" overlay={<Tooltip>{addressErr}</Tooltip>}><i className="input_info" /></OverlayTrigger> : ''}
                                </div>


                                


                            <div className="col-md-12 pro_wrapper"> 

                            <label>Secondary Emergency Contact</label>            
                                <div className="row">
                                <div className="col-md-6 form-group">
                                    <input
                                        type="text"
                                        onChange={handelChange}
                                        value={secondaryContact}
                                        className="form-control"
                                        name="secondaryContact"
                                        placeholder="Enter Secondary Contact FULL Name" />
                                </div>


                                <div className="col-md-6 form-group">
                                    <div className="cm_phone_field cm_phone_field_2">
                                        <img className="us_flag" src={require('../../assets/img/united-states-flag-icon.svg')} />
                                        <PhoneInput
                                            country="US"
                                            onChange={(e)=>{setSecondaryPhoneNumber(e)}}
                                            placeholder="Enter secondary phone number"
                                            className="form-control"
                                            maxLength={16}
                                            value={secondaryPhoneNumber}
                                            name="phone"/>
                                    </div>
                                </div>

                                <div className="col-md-12 form-group">
                                    <input
                                        type="email"
                                        onChange={e => setSecondaryEmail(e.target.value)}
                                        value={secondaryEmail}
                                        className="form-control"
                                        name="secondaryEmail"
                                        placeholder="Enter Secondary Email" />
                                </div>
                                </div>
                            </div>    


                                <div className="col-md-12 form-group  text-center">
                                    <button style={{minWidth: '130px'}} className={`btn btn-primary rounded-0 ${btnloder ? 'btnLoader' : ''}`}>Save</button>
                                </div>         
                                    
                            </div>
                       
                    </div>
                </div>
                </form>   
            </div>


        </React.Fragment>
        )

}



const mapStateToProps = state => {
    let itemsincart = state.cartReducer.cartitems;
    let userProfile = state?.user?.userProfile;
    return {
        itemsincart,
        userProfile
    };
}

const mapDispatchToProps = dispatch => ({
    upadteUserProfileAction: params => dispatch(upadteUserProfileAction(params)),
});



export default connect(mapStateToProps, mapDispatchToProps)(ProfileEdit);