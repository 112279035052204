import React, { useState, Fragment, useEffect } from 'react';
import { getInboxService } from '../../services/inboxService';
import { Empty } from 'antd';
import { connect } from 'react-redux';
import { activeUserChatWindowAction, getChatUserListCalbackAction } from '../../store/inbox';
import { getHtml, removeHtmlTags } from '../../helper';

const ChatUserList = props => {
    const [list, setList] = useState([]);
    const [search, setSearch] = useState('');
    const [chatTab, setChatTab] = useState(1);


    const getList = () => {
        let query = `?search=${search}`
        getInboxService(query).then(res => {
            props.getChatUserListCalbackAction(0);
            if(res?.data?.statusCode){
                setList(res.data.responseData.result)
            }
        })
    }

    useEffect(() => {
        getList()
    }, [search]);

    useEffect(() => {
        if(props.getChatUserListCalback){
            getList(); 
        }
    }, [props.getChatUserListCalback])



    return(
        <div className="chat_user_list_wrapper">

                <div className="contact_search">
                    <i className="icon-search" />
                    <input
                        type="text"
                        value={search}
                        onChange={e => setSearch(e.target.value)}
                        placeholder="Search" />
                </div>

            {/* <div className='me_pro_user'>
                <img src={props?.userProfile?.profileImage || require('../../assets/img/user.png')} />
                <p>{props?.userProfile?.name}</p>
            </div> */}

            <div className='chat_tabs'>
                <span className={chatTab == 1 ? 'active' : ''} onClick={() => setChatTab(1)}>Unread</span>
                <span className={chatTab == 2 ? 'active' : ''} onClick={() => setChatTab(2)}>Recents</span>
                <span className={chatTab == 3 ? 'active' : ''} onClick={() => setChatTab(3)}>Starred</span>
                <span className={chatTab == 4 ? 'active' : ''} onClick={() => setChatTab(4)}>All</span>
            </div>
            <ul>
                {
                    list.length ? list.map(item => (
                        <li key={item.groupId} className={`d-flex align-items-center ${item.groupId == props?.activeUserChatWindow?.groupId ? 'active' : ''}`} onClick={() => props.activeUserChatWindowAction(item)}>
                            {item.type == 1 ? item.userId?.length ? <Fragment>
                                <img className="chat_user_img" src={item.userId[0].profileImage ? item.userId[0].profileImage : require('../../assets/img/user.png')} />
                                <div>
                                    <p>
                                        {item.userId[0].name} {item.count ? <span className="msg_count">{item.count}</span> : ''}
                                        <br />
                                        <small>{removeHtmlTags(item.lastMessage)}</small>
                                    </p>
                                    
                                </div>
                            </Fragment> : '' : ''}

                            {item.type == 2 ? <Fragment>
                                <img className="chat_user_img" src={item.groupImage ? item.groupImage : require('../../assets/img/user.png')} />
                            <p>
                                {item.groupName}
                                <br />
                                <small>{removeHtmlTags(item.lastMessage)}</small>
                            </p>
                            
                            </Fragment> : ''}

                
                        </li>
                    )) : <li className="cm_empty"><Empty description="Data Not Found" /></li>
                }
            </ul>
        </div>
    )
}



const mapStateToProps = state => {
    let { userProfile } = state.user;
    let { getChatUserListCalback, activeUserChatWindow } = state.inbox;
    return {
        userProfile,
        getChatUserListCalback,
        activeUserChatWindow
    };
}
  
const mapDispatchToProps = dispatch => ({
    getChatUserListCalbackAction: params => dispatch(getChatUserListCalbackAction(params)),
    activeUserChatWindowAction: params => dispatch(activeUserChatWindowAction(params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ChatUserList);