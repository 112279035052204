import React, { useEffect, useState, useReducer, Fragment } from 'react';
import { OverlayTrigger, Tooltip, Modal } from 'react-bootstrap';
import PhoneInput from 'react-phone-number-input/input';
import { InputNumber, Select, DatePicker } from 'antd';
import InputMask from 'react-input-mask';
import donationBg2 from '../../assets/img/donation-bg2.jpg';
import { connect } from 'react-redux';
import { AES } from 'crypto-js';
import { Link } from 'react-router-dom';
import donationBgImage from '../../assets/img/donation-bg2.jpg';
import settings from '../../config/settings';

import {
    viewProgramDetail,
    getCustomerCardsService,
    donateService,
    createCustomerCardService
} from '../../services/programService';
import { isValidEmail } from '../../helper';

const { Option } = Select;

const addZero = num => {
    if( num.toString().length < 2 )
    return "0" + num;
    return num.toString();
}


const Donate = props => {
    const programId = props.match.params.programId;
    const [programName, setProgramName] = useState('');
    const [btnLoader, setBtnLoader] = useState(false);
    const [sucessModal, setSucessModal] = useState(false);
    const [campCode, setCampCode] = useState('');
    const [campId, setCampId] = useState('');
    const [customerProfileId, setCustomerProfileId] = useState('');
    const [cardList, setCardList] = useState([]);  
    const [paymentMethod, setPaymentMethod] = useState(1); 
    const [customerPaymentProfileId, setCustomerPaymentProfileId] = useState('');
    const [customerPaymentProfileIdError, setCustomerPaymentProfileIdError] = useState('');  
    const [customerPaymentProfileIdErr, setCustomerPaymentProfileIdErr] = useState('');
    const [cvcError, setCvcError] = useState('');
    const [isNewCard, setIsNewCard] = useState(false);
    const [nextPage, setNextPage] = useState(1);


    const [addCardBtnLoader, setAddCardBtnLoader] = useState(false);
    const [cardNo, setCardNo] = useState('');
    const [cardNoErr, setCardNoErr] = useState('');
    const [expDate, setExpDate] = useState('');
    const [month, setMonth] = useState('');
    const [year, setYear] = useState('');
    const [expDateErr, setExpDateErr] = useState('');
    const [cvc, setCvc] = useState('');
    const [cvcErr, setCvcErr] = useState('');
    const [firstName, setFirstName] = useState('');
    const [firstNameErr, setFirstNameErr] = useState('');
    const [lastName, setLastName] = useState('');
    const [zipCode, setZipCode] = useState('');
    const [zipCodeErr, setZipCodeErr] = useState('');


    const [userInput, setUserInput] = useReducer((state, newState) => ({...state, ...newState}),
        {
            name: '',
            donerEmail: '',
            phoneNo: '',
            amount: '',
            message: '',
            bankAccountType: '',
            bankAccountNum: '',
            routingNumber: '',
            nameOnAccount: '',
            firstName: '',
            lastName: '',
            email: '',
            phone: '',
            address: '',
            city: '',
            state: '',
            country: '',
            zip: ''
        }
    );


    

    const [error, setError] = useReducer((state, newState) => ({...state, ...newState}),
        {
            name: '',
            donerEmail: '',
            phoneNo: '',
            amount: '',
            bankAccountNum: '',
            routingNumber: '',
            nameOnAccount: '',
            bankAccountType: '',
            firstName: '',
            lastName: '',
            email: '',
            phone: '',
            address: '',
            city: '',
            state: '',
            country: '',
            zip: ''
        }
    );



    const handleChange = e => {
        setUserInput({[e.target.name]: e.target.value});
        setError({[e.target.name]: ''});
    }


    const handlePhoneNoChange = e => {
        setUserInput({phoneNo: e});
        setError({phoneNo: ''});
    }


    const handleNumberChange = (e, name) => {
        setUserInput({[name]: e});
        setError({[name]: ''});
    }


    const handleNextPage = () => {
        if(handleValidate()){
            setNextPage(2);
        }
    }


    const handleValidate = () => {
        let validate = true;
        let { 
            name,
            donerEmail,
            phoneNo,
            amount,
            bankAccountNum,
            routingNumber,
            nameOnAccount,
            bankAccountType,
            firstName,
            lastName,
            email,
            phone,
            address,
            city,
            state,
            zip,
            country,
        } = userInput;

        if(name == ''){
            setError({name: 'Name is required'});
            validate = false
        }

        if(donerEmail == ''){
            setError({donerEmail: 'Email is required'});
            validate = false
        }else if(!isValidEmail(donerEmail)){
            setError({donerEmail: 'Email is not valid'});
            validate = false
        }

        if(phoneNo == ''){
            setError({phoneNo: 'Phone number is required'});
            validate = false
        }

        if(amount == ''){
            setError({amount: 'Amount is required'});
            validate = false
        }

        if(firstName == ''){
            setError({firstName: 'First name is required'});
            validate = false
        }else if(firstName.length > 50){
            setError({firstName: 'First name is to large max limit 50'});
            validate = false;
        }

        if(lastName == ''){
            setError({lastName: 'Last name is required'});
            validate = false
        }

        if(email == ''){
            setError({email: 'Email is required'});
            validate = false
        }else if(!isValidEmail(email)){
            setError({email: 'Email is not valid'});
            validate = false
        }

        if(phone == ''){
            setError({phone: 'Phone is required'});
            validate = false
        }
        if(address == ''){
            setError({address: 'Address is required'});
            validate = false
        }
        if(city == ''){
            setError({city: 'City is required'});
            validate = false
        }
        if(state == ''){
            setError({state: 'State is required'});
            validate = false
        }
        if(zip == ''){
            setError({zip: 'Zip is required'});
            validate = false
        }
        if(country == ''){
            setError({country: 'Country is required'});
            validate = false
        }

        if(paymentMethod == 4){
            if(bankAccountType == ''){
                setError({bankAccountType: 'Account type is required'});
                validate = false
            }
            if(bankAccountNum == ''){
                setError({bankAccountNum: 'Account number is required'});
                validate = false
            }
            if(routingNumber == ''){
                setError({routingNumber: 'Routing number is required'});
                validate = false
            }
            if(nameOnAccount == ''){
                setError({nameOnAccount: 'name is required'});
                validate = false
            }
        }
    

        return validate;
    }


    useEffect(() => {
       let params = `?programId=${programId}`; 
        viewProgramDetail(params).then(res => {
            if(res?.data?.statusCode){
                setProgramName(res.data.responseData.result.programData.campName);
                setCampCode(res.data.responseData.result.programData.campCode);
                setCampId(res.data.responseData.result.programData.campId);
            }
        }) 
    }, [])


    const getCards = () => {
        let query = `?isWeb=1&campCode=${campCode}`;
        getCustomerCardsService(query).then(res => {
            if(res?.data?.statusCode){
                setCardList(res.data.responseData?.result || []);
            }else{
                setCardList([]); 
            }
        })
    }
    
    
    useEffect(() => {
        getCards();
    }, [props.isNewCardAdd, campCode])

    const getCardTypeName = type => {
        switch (type) {
            case 'American Express':
                return 'AmericanExpress';
                break;
            case 'Visa Electron':
            case 'visa':
                return 'Visa';
                break;
            case 'mastercard':
            case 'Mastercard':
                return 'MasterCard';
                break;
            case 'discover':
            case 'Discover':
                return 'Discover';
                break;
            case 'china_unionpay':
            case 'ChinaUnionpay':
                return 'Unionpay';
                break;
            case 'Diners Club':
                return 'DinersClub';
                break;
            default:
                return 'Visa';
        }
    }


    const handleSubmit = e => {
        e.preventDefault();

        if(handleValidate()){
            setBtnLoader(true);
            let {
                name,
                donerEmail,
                phoneNo,
                amount,
                bankAccountNum,
                routingNumber,
                nameOnAccount,
                bankAccountType,
            } = userInput;

            let params = '';

            if(paymentMethod == 1){
                params = {
                    name,
                    isWeb: 1,
                    campId,
                    email: donerEmail,
                    phoneNo,
                    message: userInput.message,
                    amount,
                    authPayment: JSON.stringify({
                        paymentType: paymentMethod,
                        customerProfileId,
                        token: customerPaymentProfileId
                    })
                    
                }
            }


            if(paymentMethod == 4){
                params = {
                    name,
                    isWeb: 1,
                    campId,
                    email: donerEmail,
                    phoneNo,
                    message: userInput.message,
                    amount,
                    authPayment: JSON.stringify({
                        paymentType: paymentMethod,
                        bankAccountNum: parseInt(bankAccountNum),
                        routingNumber: parseInt(routingNumber),
                        nameOnAccount,
                        bankAccountType
                    })
                    
                }
            }

            donateService(params).then(res => {
                setBtnLoader(false);
                if (res.data.statusCode == 1) {
                    setSucessModal(true);
                }
            })


        }
    }

    const handlePrimarynumber =(e) =>{
        if(e!==undefined){
            if(e.length < 12 || e.length > 12 )
            {}else if(e.length == 12){setUserInput({phone: e}); setError({phone: ''})}
        }
    }


    const handleCardNumberChange = (e) => {
        const inputNumber = e.target.value.replace(/\D/g, '');
        const formattedNumber = inputNumber.replace(/(\d{4})/g, '$1 ').trim();
        setCardNo(formattedNumber);
        setCardNoErr('');
      };

      const handleCvcChange = (e) => {
        const inputNumber = e.target.value.replace(/\D/g, '');
        const formattedNumber = inputNumber.replace(/(\d{4})/g, '$1 ').trim();
        setCvc(formattedNumber);
        setCvcErr('');
      };




      const handleAddCardSubmint = () => {
        let validate = true;
    

        if(cardNo == ''){
            setCardNoErr('card number error');
            validate = false;
        }else if (!(cardNo.length == 18 || cardNo.length == 19)) {
            setCardNoErr('card number lenth error');
            validate = false;    
        }else if(cardNo.includes('_')){
            setCardNoErr('card number lenth error');
            validate = false;    
        }

        if(expDate == ''){
            setExpDateErr('card expired date error');
            validate = false;
        }

        if(zipCode == ''){
            setZipCodeErr('zip code error');
            validate = false;
        }

        if(cvc == ''){
            setCvcErr('cvc error');
            validate = false;
        }else if(cvc.includes('_')){
            setCvcErr('cvc lenth error');
            validate = false;    
        }

        if(firstName == ''){
            setFirstNameErr('first name error');
            validate = false;
        }else if(firstName.length > 50){
            setFirstNameErr('First name is to large max limit 50')
            validate = false;
        }

        if(validate){
            setAddCardBtnLoader(true);
            let _cardNo = cardNo.replace(/\s/g,'');
            const encrypted_cardNo = AES.encrypt(_cardNo, settings.api.mySecretKey).toString();
            const encrypted_cvc = AES.encrypt(cvc, settings.api.mySecretKey).toString();

            let params = {
                cardNo: encrypted_cardNo,
                expDate,
                month,
                year,
                cvc: encrypted_cvc,
                firstName,
                lastName,
                email: userInput.email,
                isWeb: 1,
                campCode,
                zipCode
            }

           
            createCustomerCardService(params).then(res => {
                setAddCardBtnLoader(false);
                if(res?.data?.statusCode){
                    getCards();
                    setCardNo('');
                    setExpDate('');
                    setCvc('');
                    setFirstName('');
                    setLastName('');
                    setIsNewCard(false);
                }
            })
        }
    }



    return(
        <div className="body_container">



        <Modal
            show={sucessModal}
            animation={false}
            dialogClassName="folder_model"
            size="lg"
            centered
        >
            <Modal.Body>
                <div className="text-center p-5 pickup_modal_body">
                    <p>Thanks for donation</p>
                </div>
                <div className="text-center p-5">
                    <Link to={`/camps/${programId}`} style={{ width: '220px' }} className="btn btn-primary ml-3">Back to Program Detail</Link>
                </div>
            </Modal.Body>
        </Modal>




            <div className="cm_banner_wrapper">
                <img src={donationBg2} />
                <div className="cm_banner_text">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6">
                                <h2>Donate Now</h2>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        
            <div className="container mt-5 pb-5 donate_wrap">
                <div className="row">
                    <div className="col-lg-2" />

                    <div className="col-lg-8">
                        <h3 className="text-center">Make a difference <span>Donate Today!</span></h3>
                        <p className="text-center mb-5">This donation will go to the
                            camp/organization listed below. Charitable donations are an awesome way
                            to give back to the community and allow more people to enjoy the camp
                            experience. Donations are tax deductible and tax documents will be sent to
                            the email below. The camp providers want to thank you for your gracious
                            donations. Feel free to leave a note of encouragement for them below as
                            well!
                        </p>
                    
                    
                        <h4 className="mt-3 mb-4">Camp Name : <span>{programName}</span></h4>

                        <h4 className="mb-4 color_red">Fill your details</h4>

                        <form onSubmit={handleSubmit}>


                            {
                                nextPage == 1 ? <Fragment>
                                    <div className="row">

                                        <div className="col-md-6 form-group">
                                            <label>Name</label>
                                            <input
                                                type="text"
                                                className={`form-control ${error.name ? 'is-invalid' : ''}`}
                                                name="name"
                                                value={userInput.name}
                                                onChange={handleChange} />

                                            {error.name ? <OverlayTrigger placement="left" overlay={<Tooltip>{error.name}</Tooltip>}><i className="input_info" /></OverlayTrigger> : ''}
                                        </div>

                                        <div className="col-md-6 form-group">
                                            <label>Email</label>
                                            <input
                                                type="email"
                                                className={`form-control ${error.donerEmail ? 'is-invalid' : ''}`}
                                                name="donerEmail"
                                                value={userInput.donerEmail}
                                                onChange={handleChange} />

                                            {error.donerEmail ? <OverlayTrigger placement="left" overlay={<Tooltip>{error.donerEmail}</Tooltip>}><i className="input_info" /></OverlayTrigger> : ''}
                                        </div>

                                        </div>

                                        <div className="row">

                                        <div className="col-md-6 form-group">
                                            <label>Phone Number</label>
                                            <PhoneInput
                                                country="US"
                                                className={`form-control ${error.phoneNo ? 'is-invalid' : ''}`}
                                                onChange={handlePhoneNoChange}
                                                value={userInput.phoneNo}
                                                maxLength={16}
                                                />
                                        
                                            {error.phoneNo ? <OverlayTrigger placement="left" overlay={<Tooltip>{error.phoneNo}</Tooltip>}><i className="input_info" /></OverlayTrigger> : ''}
                                        </div>

                                        <div className="col-md-6 form-group">
                                            <label>Donation Amount</label>
                                            <InputNumber
                                                className={`form-control ${error.amount ? 'is-invalid' : ''}`}
                                                value ={userInput.amount}
                                                onChange={e => handleNumberChange(e, 'amount')}
                                            />


                                            {error.amount ? <OverlayTrigger placement="left" overlay={<Tooltip>{error.amount}</Tooltip>}><i className="input_info" /></OverlayTrigger> : ''}
                                        </div>

                                        </div>


                                        <div className="form-group">
                                        <label>Message [Optional]</label>
                                        <textarea
                                            placeholder="Write a message"
                                            className="form-control"
                                            name="message"
                                            value={userInput.message}
                                            onChange={handleChange} />
                                        </div>




                                
                                <h4 className="mb-4">Billing Information</h4>


                                <div className="row">
                                    
                                    <div className="col-md-6 form-group cm_err_toltip">
                                        <label>Billing First Name</label>
                                        <input
                                            type="text"
                                            name="firstName"
                                            value={userInput.firstName}
                                            className={`form-control ${error.firstName ? 'is-invalid' : ''}`}
                                            onChange={handleChange} />

                                        {error.firstName ? <OverlayTrigger placement="left" overlay={<Tooltip>{error.firstName}</Tooltip>}><i className="input_info" /></OverlayTrigger> : ''}
                                    </div>

                                    <div className="col-md-6 form-group cm_err_toltip">
                                        <label>Billing Last Name</label>
                                        <input
                                            type="text"
                                            name="lastName"
                                            value={userInput.lastName}
                                            className={`form-control ${error.lastName ? 'is-invalid' : ''}`}
                                            onChange={handleChange} />

                                        {error.lastName ? <OverlayTrigger placement="left" overlay={<Tooltip>{error.lastName}</Tooltip>}><i className="input_info" /></OverlayTrigger> : ''}
                                    </div>
                                    
                                </div>



                                <div className="row">
                                    
                                    <div className="col-md-6 form-group cm_err_toltip">
                                        <label>Email</label>
                                        <input
                                            type="email"
                                            name="email"
                                            value={userInput.email}
                                            className={`form-control ${error.email ? 'is-invalid' : ''}`}
                                            onChange={handleChange} />

                                        {error.email ? <OverlayTrigger placement="left" overlay={<Tooltip>{error.email}</Tooltip>}><i className="input_info" /></OverlayTrigger> : ''}
                                    </div>

                                    <div className="col-md-6 form-group cm_err_toltip">
                                    <label>Phone</label>
                                    <div className="cm_phone_field">
                                        <img className="us_flag" src={require('../../assets/img/united-states-flag-icon.svg')} />
                                        <PhoneInput
                                            country="US"
                                            value={userInput.phone}
                                            className={`form-control ${error.phone ? 'is-invalid' : ''}`}
                                            onChange={handlePrimarynumber}
                                            maxLength={16}
                                            />
                                    </div>

                                        {error.phone ? <OverlayTrigger placement="left" overlay={<Tooltip>{error.phone}</Tooltip>}><i className="input_info" /></OverlayTrigger> : ''}
                                    </div>
                                    
                                </div>



                                <div className="row">
                                    
                                    <div className="col-md-6 form-group cm_err_toltip">
                                        <label>Address</label>
                                        <input
                                            type="text"
                                            name="address"
                                            value={userInput.address}
                                            className={`form-control ${error.address ? 'is-invalid' : ''}`}
                                            onChange={handleChange} />

                                        {error.address ? <OverlayTrigger placement="left" overlay={<Tooltip>{error.address}</Tooltip>}><i className="input_info" /></OverlayTrigger> : ''}
                                    </div>

                                    <div className="col-md-6 form-group cm_err_toltip">
                                        <label>City</label>
                                        <input
                                            type="text"
                                            name="city"
                                            value={userInput.city}
                                            className={`form-control ${error.city ? 'is-invalid' : ''}`}
                                            onChange={handleChange} />

                                        {error.city ? <OverlayTrigger placement="left" overlay={<Tooltip>{error.city}</Tooltip>}><i className="input_info" /></OverlayTrigger> : ''}
                                    </div>
                                    
                                </div>



                                <div className="row">
                                    
                                    <div className="col-md-6 form-group cm_err_toltip">
                                        <label>STATE / PROVINCE</label>
                                        <input
                                            type="text"
                                            name="state"
                                            value={userInput.state}
                                            className={`form-control ${error.state ? 'is-invalid' : ''}`}
                                            onChange={handleChange} />

                                        {error.state ? <OverlayTrigger placement="left" overlay={<Tooltip>{error.state}</Tooltip>}><i className="input_info" /></OverlayTrigger> : ''}
                                    </div>

                                    <div className="col-md-6 form-group cm_err_toltip">
                                        <label>ZIP / POSTAL CODE</label>
                                        <input
                                            type="text"
                                            name="zip"
                                            value={userInput.zip}
                                            className={`form-control ${error.zip ? 'is-invalid' : ''}`}
                                            onChange={handleChange} />

                                        {error.zip ? <OverlayTrigger placement="left" overlay={<Tooltip>{error.zip}</Tooltip>}><i className="input_info" /></OverlayTrigger> : ''}
                                    </div>
                                    
                                </div>



                                <div className="row">
                                    
                                    <div className="col-md-6 form-group cm_err_toltip">
                                        <label>Country</label>
                                        <input
                                            type="text"
                                            name="country"
                                            value={userInput.country}
                                            className={`form-control ${error.country ? 'is-invalid' : ''}`}
                                            onChange={handleChange} />

                                        {error.country ? <OverlayTrigger placement="left" overlay={<Tooltip>{error.country}</Tooltip>}><i className="input_info" /></OverlayTrigger> : ''}
                                    </div>
                                    
                                </div>
                                </Fragment>
                                :
                                
                                <Fragment>
                                     <h4 className="mb-4">Select Payment Method</h4>
                                        <p>
                                            <span className="cm_radio_wrap ml-0">
                                                <input type="radio" onChange={() => setPaymentMethod(1)} checked={paymentMethod == 1} name="paymentMethod" />
                                                <span />
                                                Credit Card / Debit Card
                                            </span>
                                        </p>
                                        {
                                            paymentMethod == 1 ? <Fragment>

                                        <ul className="card_list">
                                            {cardList.length ? cardList.map(item => (
                                                    <li key={item.signature} className={customerPaymentProfileId === item.card.token ? 'active' : ''}>
                                                
                                                    <div className="check_box_wrapper cm_dark_check pt-1">
                                                        <input
                                                            checked={customerPaymentProfileId === item.card.token}
                                                            onChange={() => ((setCustomerPaymentProfileId(item.card.token), setCustomerProfileId(item.card.token)), setCustomerPaymentProfileIdErr(''))}
                                                            type="radio"
                                                            name="gender"
                                                            value="male" />
                                                        <span className="check_box"><span></span></span>
                                                    </div>
                                                    <div className="p_card_img ml-4">
                                                        <img src={item?.card?.brand ? require(`../../assets/img/${getCardTypeName(item.card.brand)}.svg`) : require(`../../assets/img/Visa.svg`)} />
                                                    </div>
                                                    <p>********{item.card.acct_last_four}</p>
                                                </li>

                                                )) : ''
                                            }
                                        </ul>

                                                        {customerPaymentProfileIdError ? <span className="cm_card_error">{customerPaymentProfileIdError}</span> : ''}

                                                        {
                                                            isNewCard ? (
                                                                <div className="card_inputs add_card_form">
                                                                    <div className="form-group">
                                                                        <label>Card Number</label>
                                                                            <input
                                                                                type="text"
                                                                                className={`form-control ${cardNoErr ? 'is-invalid' : ''}`}
                                                                                name="cardNumber"
                                                                                placeholder="XXXX XXXX XXXX XXXX"
                                                                                value={cardNo}
                                                                                maxLength={19}
                                                                                onChange={handleCardNumberChange} />

                                                                            {cardNoErr ? <OverlayTrigger placement="left" overlay={<Tooltip>{cardNoErr}</Tooltip>}><i className="input_info" /></OverlayTrigger> : ''}
                                                                    </div>

                                                                    <div className="row">
                                                                        <div className="col form-group">
                                                                            <label>Expiry Date</label>
                                                                            <DatePicker
                                                                                className={`form-control ${expDateErr ? 'is-invalid' : ''}`}
                                                                                format="MM/YY"
                                                                                picker="month"
                                                                                placeholder="MM/YY"
                                                                                onKeyPress={() => setExpDateErr('')}
                                                                                onChange={e => (setMonth(`${addZero(e._d.getMonth() + 1)}`), setYear(`${e._d.getFullYear().toString()}`), setExpDate(`${addZero(e._d.getMonth() + 1)}${e._d.getFullYear().toString()}`), setExpDateErr(''))} />

                                                                            {expDateErr ? <OverlayTrigger placement="left" overlay={<Tooltip>{expDateErr}</Tooltip>}><i className="input_info" /></OverlayTrigger> : ''}
                                                                        </div>
                                                                        <div className="col form-group">
                                                                            <label>CVC</label>
                                                                            {/* <InputMask
                                                                                className={`form-control ${cvcErr ? 'is-invalid' : ''}`}
                                                                                placeholder="XXX"
                                                                                mask="999"
                                                                                onKeyPress={() => setCvcErr('')}
                                                                                onChange={e => setCvc(e.target.value)} /> */}

                                                                            <input
                                                                                type="text"
                                                                                className={`form-control ${cvcErr ? 'is-invalid' : ''}`}
                                                                                name="cvc"
                                                                                placeholder="XXX"
                                                                                value={cvc}
                                                                                maxLength={4}
                                                                                onChange={handleCvcChange} />

                                                                                {cvcErr ? <OverlayTrigger placement="left" overlay={<Tooltip>{cvcErr}</Tooltip>}><i className="input_info" /></OverlayTrigger> : ''}
                                                                        </div>
                                                                    </div>

                                                                    <div className="form-group">
                                                                        <label>Cardholder First Name</label>
                                                                        <input
                                                                            type="text"
                                                                            className={`form-control ${firstNameErr ? 'is-invalid' : ''}`}
                                                                            placeholder="Cardholder First Name"
                                                                            onKeyPress={() => setFirstNameErr('')}
                                                                            onChange={e => setFirstName(e.target.value)} />

                                                                            {firstNameErr ? <OverlayTrigger placement="left" overlay={<Tooltip>{firstNameErr}</Tooltip>}><i className="input_info" /></OverlayTrigger> : ''}
                                                                    </div>

                                                                    <div className="form-group">
                                                                        <label>Cardholder Last Name</label>
                                                                        <input
                                                                            type="text"
                                                                            className="form-control"
                                                                            placeholder="Cardholder Last Name"
                                                                            onChange={e => setLastName(e.target.value)} />
                                                                    </div>

                                                                    <div className="form-group">
                                                                        <label>Zip Code</label>
                                                                        <input
                                                                            type="text"
                                                                            className={`form-control ${zipCodeErr ? 'is-invalid' : ''}`}
                                                                            placeholder="Zip Code"
                                                                            onChange={e => (setZipCode(e.target.value), setZipCodeErr(''))} />

                                                                            {zipCodeErr ? <OverlayTrigger placement="left" overlay={<Tooltip>{zipCodeErr}</Tooltip>}><i className="input_info" /></OverlayTrigger> : ''}
                                                                    </div>


                                                                    <div className="d-flex justify-content-between">
                                                                    <span onClick={() => setIsNewCard(false)} className="btn btn-info rounded-0 w-100 mr-2">Cancel</span>
                                                                    <span onClick={addCardBtnLoader ? null : handleAddCardSubmint} className={`btn btn-primary rounded-0 w-100  ${addCardBtnLoader ? 'btnLoader' : ''}`}>Add</span>
                                                                    </div>


                                                                    </div>
                                                            ) : <button className="btn btn-primary" onClick={() => setIsNewCard(true)}>Add New Card</button>
                                                        }
                                            </Fragment> : ''
                                        }


                                        <p>
                                            <span className="cm_radio_wrap ml-0">
                                                <input type="radio" onChange={() => setPaymentMethod(4)} checked={paymentMethod == 4} name="paymentMethod" />
                                                <span />
                                                Internet Banking
                                            </span>
                                        </p>



                                        {
                                            paymentMethod == 4 ? <Fragment>

                                                <div className="card_inputs">

                                                <div className="form-group">
                                                    <label>Select Account Type</label>
                                                    
                                                    <Select
                                                        className={`form-control ${error.bankAccountType ? 'is-invalid' : ''}`}
                                                        placeholder="Select Account Type"
                                                        onChange={e => handleNumberChange(e, 'bankAccountType')}
                                                    >    
                                                        <Option value="checking">Checking</Option>
                                                        <Option value="savings">Savings</Option>
                                                        <Option value="businessChecking">business Checking</Option>
                                                    </Select>

                                                    {error.bankAccountType ? <OverlayTrigger placement="left" overlay={<Tooltip>{error.bankAccountType}</Tooltip>}><i className="input_info" /></OverlayTrigger> : ''}
                                                </div>

                                                <div className="form-group">
                                                        <label>Routing Number</label>
                                                        <input
                                                            type="number"
                                                            name='routingNumber'
                                                            className={`form-control ${error.routingNumber ? 'is-invalid' : ''}`}
                                                            value ={userInput.routingNumber}
                                                            placeholder="Account Number"
                                                            onChange={handleChange}
                                                        />

                                                        {error.routingNumber ? <OverlayTrigger placement="left" overlay={<Tooltip>{error.routingNumber}</Tooltip>}><i className="input_info" /></OverlayTrigger> : ''}
                                                    
                                                    </div>
                                            
                                                    <div className="form-group">
                                                        <label>Account Number</label>
                                                        <input
                                                            type="number"
                                                            name='bankAccountNum'
                                                            className={`form-control ${error.bankAccountNum ? 'is-invalid' : ''}`}
                                                            value ={userInput.bankAccountNum}
                                                            placeholder="Account Number"
                                                            onChange={handleChange}
                                                        />

                                                        {error.bankAccountNum ? <OverlayTrigger placement="left" overlay={<Tooltip>{error.bankAccountNum}</Tooltip>}><i className="input_info" /></OverlayTrigger> : ''}
                                                    
                                                    </div>


                                                    <div className="form-group">
                                                        <label>Account Holder Name</label>
                                                    
                                                            <input
                                                                type="text"
                                                                className={`form-control ${error.nameOnAccount ? 'is-invalid' : ''}`}
                                                                name="nameOnAccount"
                                                                value={userInput.nameOnAccount}
                                                                onChange={handleChange}
                                                                placeholder="Account Holder Name" />

                                                            {error.nameOnAccount ? <OverlayTrigger placement="left" overlay={<Tooltip>{error.nameOnAccount}</Tooltip>}><i className="input_info" /></OverlayTrigger> : ''}
                                                    </div>




                                                </div>



                                            </Fragment> : ''
                                        }
                                </Fragment>
                            }



                            <div className="text-center">
                                {nextPage == 1 ? <span className="btn btn-primary rounded-0 pl-5 pr-5" onClick={handleNextPage}>Next step</span> : ''}
                                {nextPage == 2 ? 
                                    <Fragment>
                                        <span className="btn btn-dark rounded-0 pl-5 pr-5 mr-3" onClick={() => setNextPage(1)}>Back</span>
                                        <button disabled={btnLoader} type="submit" className={`btn btn-primary rounded-0 ${btnLoader ? 'btnLoader' : ''}`} style={{width: '160px'}}>Proceed to Pay</button>
                                    </Fragment>
                                     : ''}
                            </div>

                        </form>

                    </div>
                </div>
            </div>



    </div>
    )
}

const mapStateToProps = state => {
    let { loggedIn } = state.user;    
    let { isNewCardAdd } = state.ui;    
    return{
        loggedIn,
        isNewCardAdd
    }
}

export default connect(mapStateToProps, null)(Donate);