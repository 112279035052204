import React, { useState, Fragment, useEffect } from 'react';
import { connect } from 'react-redux';

import UserImg from '../../assets/img/user.png'

const AboutActiveChatUser = props => {

    return(
        <div className="about_active_chat_user">
                <img src={props?.activeUserChatWindow?.userId[0]?.profileImage || UserImg} />
                <h4>{props?.activeUserChatWindow?.userId[0]?.name}</h4>
        </div>
    )
}


const mapStateToProps = state => {
    let { userProfile } = state.user;
    let { activeUserChatWindow } = state.inbox;
    return {
        userProfile,
        activeUserChatWindow
    };
}
  
const mapDispatchToProps = dispatch => ({

});

export default connect(mapStateToProps, mapDispatchToProps)(AboutActiveChatUser);