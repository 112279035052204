import React, {useState, useEffect, Fragment} from 'react';
import { notification } from 'antd';
import PhoneInput from 'react-phone-number-input/input';
import { connect } from 'react-redux';

import {profileInformation, updateProfile} from '../../services/campersServices';
import {upadteUserProfileAction} from '../../store/user';
import {ProgramLoader} from '../reuse/SVG';


const CampDirectorProfileEdit = props => {
    const[name, setFirstName]                       = useState();
    const[nameError, setNameError]                  = useState(false);
    const[lastName, setLastName]                    = useState('');
    const[phone, setPhoneNumber]                    = useState();
    const[campName, setCampName]                    = useState();
    const[campCode, setCampCode]                    = useState();
    const[campAddress, setCampAddress]              = useState();
    const[loader, setLoader]                        = useState(true);
    const[profileImage, setProfileImage]            = useState();
    const[profileimageurl, setProfileImageurl]      = useState();
    const[btnloder, setBtnLoader]                   = useState(false);
    const[editMode, setEditMode]                    = useState(false);

    useEffect(() => {
        profileInformation().then(res => {
            if(res?.data?.statusCode){
               let profileDetails =  res.data.responseData.result
               setFirstName(profileDetails.name)
               setLastName(profileDetails.lastName)
               setPhoneNumber(profileDetails.phone)
               setCampName(profileDetails.campInfo.campName)
               setCampAddress(profileDetails.campInfo.address) 
               setCampCode(profileDetails.campInfo.campCode)   
               setProfileImageurl(profileDetails.profileImage)   
               setLoader(false);  
            }
            else{
                setLoader(false);  
            }
        })
    }, [])



    const handelPhoneNumber = (e) =>{
        setPhoneNumber(e)
    }

    const handelChange = (e) =>{
        let name = e.target.name;
        let value = e.target.value;
        if(name=='name'){setFirstName(value); setNameError(false)}    
    }

    const handelSubmit = (e) =>{
        e.preventDefault();
        setBtnLoader(true);
        let erro_count = 0;
        if(name=='' || name===undefined){setNameError(true); setBtnLoader(true); erro_count++}
        if(erro_count==0)
        {
            let form_data = new FormData();
            form_data.append('profileImage',profileImage)
            form_data.append('lastName',lastName)
            form_data.append('name',name);
            form_data.append('phone',phone);
            form_data.append('userId',props.userProfile._id);
            updateProfile(form_data).then(res => {
                if(res?.data?.statusCode){
                    let newres = res.data.responseData.result;
                    newres.UserType = 2;
                    props.upadteUserProfileAction(newres);
                    setBtnLoader(false);
                    notification.open({
                        message: 'Profile',
                        description:
                          'Profile Updated Successfully',
                      });
                }
               
            })

        }
    }

    const upload_image = (e) =>{
        if (e.target.files[0]) {
            setProfileImage(e.target.files[0]);
            const reader = new FileReader();
            reader.addEventListener("load", () => {
                setProfileImageurl(reader.result);
            });
            reader.readAsDataURL(e.target.files[0]);
          }
    }



    return(

        <React.Fragment>
              {loader ? <div className="page_loader"><ProgramLoader /></div> : ''}
            <div className="card mb-4">
                <h4 className="text-center">User Information</h4>
                <form onSubmit={handelSubmit} encType="multipart/form-data">       
                <div className="profile_upload">
                    <img src={profileimageurl ? profileimageurl : require('../../assets/img/camps-user.png')} />
                    {editMode ? <Fragment>
                        <input onChange={upload_image} type="file" accept="image/x-png,image/gif,image/jpeg"/>
                    <i className="icon-camera-plus" />
                    </Fragment> : ''}
                    
                </div>

                <div className="row">
                    <div className="col-lg-1" />
                    <div className="col-lg-10">
                    
                        <div className="row">
                                <div className="col-md-6 form-group">
                                    <input type="text" disabled = {editMode ? false : true} name="name" onChange={handelChange} value={name} className={`form-control ${ nameError ? 'is-invalid' : '' }`} placeholder="First Name" />
                                </div>
 
                                <div className="col-md-6 form-group">
                                    <input type="text" disabled = {editMode ? false : true} value={lastName} onChange={(e)=>{setLastName(e.target.value)}} className="form-control" placeholder="Last Name" />
                                </div>

                                <div className="col-md-6 form-group">
                                <div className="cm_phone_field cm_phone_field_2">
                                        <img className="us_flag" src={require('../../assets/img/united-states-flag-icon.svg')} />
                                        <PhoneInput
                                            country="US"
                                            placeholder="Phone Number"
                                            className="form-control"
                                            onChange={(e)=>{handelPhoneNumber(e)}}
                                            maxLength={16}
                                            value={phone}
                                            disabled = {editMode ? false : true}
                                            name="phone"/>
                                    </div>
                                </div>


 
                                <div className="col-md-6 form-group">
                                    <input type="text" value={campName}  className="form-control" placeholder="Camp Name" readOnly/>
                                </div>


                                <div className="col-md-6 form-group">
                                    <input type="text" value={campCode}  className="form-control" placeholder="Org Code" readOnly/>
                                </div>


                                <div className="col-md-6 form-group">
                                    <input type="text" value={campAddress}  className="form-control" placeholder="Address" readOnly/>
                                </div>                                                                




                                <div className="col-md-12 form-group  text-center">
                                {
                                  !editMode ? 
                                    <span onClick={()=>{setEditMode(true)}} style={{minWidth: '130px'}} className="btn btn-primary rounded-0">Edit</span> :
                                    <React.Fragment>
                                    
                                    <span onClick={()=>{setEditMode(false)}} style={{minWidth: '130px'}} className="btn btn-light rounded-0 mr-2 ">Cancel</span>
                                    
                                    <button onClick={()=>{setBtnLoader(true)}} style={{minWidth: '130px'}} className={`btn btn-primary rounded-0 ${btnloder ? 'btnLoader' : ''}`}>Save Changes</button>
                                    </React.Fragment>
                                }    
                                 
                                 
                                
                                </div>         
                                    
                            </div>
                       
                    </div>
                </div>
                </form>   
            </div>
        </React.Fragment>
        )

}



const mapStateToProps = state => {
    let { userProfile } = state.user;
    return {
      state,
      userProfile
    };
  }
  
const mapDispatchToProps = dispatch => ({
    upadteUserProfileAction: params => dispatch(upadteUserProfileAction(params)),
});
  
export default connect(mapStateToProps, mapDispatchToProps)(CampDirectorProfileEdit);

